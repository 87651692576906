'use strict';

(function () {
    // Setting up route
    angular.module('help').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        // Users settings state routing
        $stateProvider.state('help', {
            url: '/help',
            templateUrl: 'modules/help/features/help/help.client.view.html',
            controller: 'HelpController',
            controllerAs: 'help',
            data: {
                pageTitle: 'onCall Help'
            },
            params: {
                inGlobalEdit: false,
                helpPage: true
            }
        });
    }
})();