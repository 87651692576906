'use strict';

/* globals _ */
(function () {
    // Authentication service for user variables
    angular.module('users').factory('Authentication', authService);

    authService.$inject = [];

    function authService() {
        var svc = {};
        svc.user = window.user;
        svc.hasRole = hasRole;

        return svc;

        function hasRole(roles) {
            if (svc.user.roles.indexOf('developer') > -1) {
                return true;
            }
            var intArray = _.intersection(roles, svc.user.roles);
            if (intArray.length) {
                return true;
            }
            return false;
        }
    }
})();