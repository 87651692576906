'use strict';

(function () {
    angular.module('organizations').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('organizations', {
            abstract: true,
            url: '/organizations',
            template: '<ui-view/>',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('organizations.list', {
            url: '',
            templateUrl: 'modules/organizations/features/organizations-crud/views/organizations-list.client.view.html',
            controller: 'OrganizationsListController',
            controllerAs: 'vm',
            resolve: {
                organizationListResolve: getOrganizationList
            },
            data: {
                pageTitle: 'Organizations List',
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('organizations.create', {
            url: '/create',
            templateUrl: 'modules/organizations/features/organizations-crud/views/organizations-form.client.view.html',
            controller: 'OrganizationsCRUDController',
            controllerAs: 'vm',
            resolve: {
                organizationResolve: newOrganization
            },
            data: {
                pageTitle: 'Organizations Create',
                roles: ['admin', 'developer']
            }
        }).state('organizations.edit', {
            url: '/:organizationId/edit',
            templateUrl: 'modules/organizations/features/organizations-crud/views/organizations-form.client.view.html',
            controller: 'OrganizationsCRUDController',
            controllerAs: 'vm',
            resolve: {
                organizationResolve: getOrganization
            },
            data: {
                pageTitle: 'Edit Organization {{ organizationResolve.name }}'
            }
        }).state('organizations.view', {
            url: '/:organizationId',
            templateUrl: 'modules/organizations/features/organizations-crud/views/organizations-view.client.view.html',
            controller: 'OrganizationsCRUDController',
            controllerAs: 'vm',
            resolve: {
                organizationResolve: getOrganization
            },
            data: {
                pageTitle: 'Organization {{ articleResolve.name }}'
            }
        });
    }

    getOrganization.$inject = ['$stateParams', 'Organizations'];
    function getOrganization($stateParams, Organizations) {
        return Organizations.get({
            organizationId: $stateParams.organizationId
        }).$promise;
    }

    getOrganizationList.$inject = ['Organizations'];
    function getOrganizationList(Organizations) {
        return Organizations.query().$promise;
    }

    newOrganization.$inject = ['Organizations'];
    function newOrganization(Organizations) {
        return new Organizations();
    }
})();