'use strict';

(function () {

    angular.module('core').filter('dateIntFormat', dateIntFormatFilter);

    dateIntFormatFilter.$inject = [];

    function dateIntFormatFilter() {
        return function (input, format) {
            var dateInt = parseInt(input);
            var date = moment().year(dateInt / 10000).month(dateInt % 10000 / 100).date(dateInt % 100);
            return date.format(format);
        };
    }
})();