'use strict';

(function () {
    // Organizations controller
    angular.module('organizations.crud').controller('OrganizationsCRUDController', OrganizationsCRUDController);

    OrganizationsCRUDController.$inject = ['$rootScope', '$scope', '$state', 'organizationResolve', 'Upload'];

    function OrganizationsCRUDController($rootScope, $scope, $state, organization, Upload) {

        /*jshint validthis: true */
        var vm = this;

        vm.state = $state;
        vm.getPreviousState = getPreviousState;
        vm.getPreviousStateParams = getPreviousStateParams;

        //bindable members go here
        vm.organization = organization;
        vm.error = null;
        vm.errorOccured = null;
        vm.form = {};
        vm.remove = remove;
        vm.save = save;
        vm.checked = false;
        vm.checkfile = function () {
            //   console.log(vm.form.organizationForm.file)
        };

        //initialize the controller
        activate();
        vm.setLogoURL = function () {
            Upload.base64DataUrl(vm.picFile).then(function (url) {
                vm.organization.logoURL = url;
            });
        };
        //function declarations go here
        function activate() {}

        // Remove existing Organization
        function remove() {
            if (confirm('Are you sure you want to delete?')) {
                vm.organization.$remove($state.go('organizations.list'));
            }
        }

        // Save Organization
        function save(isValid) {
            vm.errorOccured = null;
            if (!isValid) {
                $scope.$broadcast('show-errors-check-validity', 'vm.form.organizationForm');
                return false;
            }

            if (vm.organization._id) {
                vm.organization.$update(successCallback, errorCallback);
            } else {
                vm.organization.$save(successCallback, errorCallback);
            }

            function successCallback() {
                $state.go('admin.dashboard');
            }

            function errorCallback(res) {
                vm.errorOccured = res.status + ' ' + res.statusText;
                vm.error = res.data.message;
            }
        }

        function getPreviousState() {
            // console.log($rootScope.previousState.name);
            return $rootScope.previousState.name;
        };

        function getPreviousStateParams() {
            // console.log($rootScope.previousStateParams);
            return $rootScope.previousStateParams;
        };
    }
})();