'use strict';

/* globals moment angular _ */
(function () {
    // Fleet Check schedule controller
    angular.module('stores.store-details').controller('StoreDetailsFleetCheckController', StoreDetailsFleetCheckController);

    StoreDetailsFleetCheckController.$inject = ['storeResolve', '$mdDialog', 'Authentication', 'Stores', '$scope', 'DetailsEditService', 'SchedulingService', 'uiGridConstants'];

    function StoreDetailsFleetCheckController(store, $mdDialog, Authentication, Stores, $scope, DetailsEditService, SchedulingService, uiGridConstants) {
        var vm = this;
        var authentication = Authentication;
        var viewColumns = [];
        var editColumns = [];

        var gridOptions = {
            columnDefs: viewColumns,
            enableHorizontalScrollbar: 0,
            enableVerticalScrollbar: 2,
            enableSorting: false,
            enableColumnMenus: false,
            rowHeight: 25,
            onRegisterApi: function onRegisterApi(api) {
                vm.gridApi = api;
            },
            appScopeProvider: vm
        };

        // eslint-disable-next-line no-unused-vars
        var storeListener = $scope.$on('store-update', function (event, args) {
            if (vm.store._id === args.store._id) {
                vm.store = args.store;
            }
        });

        //exposed functions
        vm.showEditIcon = showEditIcon;
        vm.showCancelSave = showCancelSave;
        vm.moveWeek = moveWeek;
        vm.startEdit = startEdit;
        vm.save = save;
        vm.cancelEdit = cancelEdit;

        //bindable members go here
        vm.store = store;
        vm.fleets = store.fleets;
        vm.edit = {};
        vm.error = null;
        vm.storeDay = moment();
        vm.day = SchedulingService.getCurrentActiveScheduleDay(vm.store);
        vm.weekStart = moment().startOf('isoWeek').toDate();
        vm.weekEnd = moment().endOf('isoWeek').toDate();
        vm.editing = false;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            gridOptions.data = vm.fleets;
            //remove the listener when the scope is destroyed
            $scope.$on('$destroy', storeListener);
        }

        function showEditIcon() {
            return !vm.editing && authentication.user.canEdit;
        }
        function showCancelSave() {
            return vm.editing || false;
        }

        function startEdit() {
            console.log('startEdit called in fleet-check');
        }

        function finishEdit() {}

        function cancelEdit() {
            vm.setGlobalEditMode(false);
            finishEdit();
        }

        function save() {
            function saveSuccess(store) {
                vm.directory = store.directory;
                finishEdit();
            }
            function saveError(err) {
                console.log(err); //eslint-disable-line no-console
            }
            // let updatedStore = vm.store.toJSON(),
            //     newInfo = vm.edit.directory;

            // updatedStore.directory = newInfo;

            // Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }

        function moveWeek(value) {
            var weekStart = moment(vm.weekStart).add(value, 'week').startOf('isoWeek');
            vm.weekStart = weekStart.toDate();
            vm.weekEnd = weekStart.endOf('isoWeek').toDate();
            loadSchedule(vm.weekStart);
        }
    }
})();