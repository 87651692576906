'use strict';

/* globals _ */
(function () {
    angular.module('users').controller('UsersController', UsersController);

    //dependency injections for the controller go here
    UsersController.$inject = ['$rootScope', 'Users', 'UserResolve', 'Authentication', 'organizations', '$scope', 'Stores', '$state', '$mdDialog'];

    function UsersController($rootScope, Users, UserResolve, Authentication, organizations, $scope, Stores, $state, $mdDialog) {

        var vm = this;

        vm.getPreviousState = getPreviousState;
        vm.getPreviousStateParams = getPreviousStateParams;
        vm.state = $state;

        //bindable members go here
        vm.user = UserResolve;
        vm.selectedStore = null;
        vm.authentication = Authentication;
        vm.organizationEdit = false;
        vm.success = null;
        vm.error = null;
        vm.organizations = organizations;
        vm.stores = null;
        vm.storeIdMap = {};
        vm.storesLoaded = false;
        vm.saving = false;
        vm.roles = [{
            'name': 'User',
            'value': 'user',
            'tooltip': 'Allows access to a single store.'
        }, {
            'name': 'Head of Organization',
            'value': 'organizationHead',
            'tooltip': 'Allows access to all stores inside the selected organization.'
        }];

        vm.developerRole = _.intersection(['developer'], Authentication.user.roles);
        vm.isDeveloper = vm.developerRole.length > 0;

        if (vm.isDeveloper === true) {
            vm.roles.push({ name: 'Admin', value: 'admin' });
        }

        if (vm.user && vm.user.organization) {
            vm.organizationEdit = vm.user.organization.userCreation ? false : true;
        }

        vm.addStore = function () {
            vm.user.stores = vm.user.stores || [];
            if (vm.user.stores.indexOf(vm.selectedStore.toString()) === -1) {
                vm.user.stores.push(vm.selectedStore);
            }
            vm.selectedStore = undefined;
        };

        vm.removeStore = function (storeId) {
            vm.user.stores = vm.user.stores || [];
            var foundIndex = vm.user.stores.indexOf(storeId.toString());
            if (foundIndex !== -1) {
                vm.user.stores.splice(foundIndex, 1);
            }
        };

        vm.save = function () {
            vm.saving = true;
            if (vm.user._id) {
                vm.update();
            } else {
                vm.create();
            }
        };

        vm.create = function () {
            vm.checked = true;
            $scope.$broadcast('show-errors-check-validity');

            if (!vm.user.roles || vm.user.roles.indexOf('user') > -1 && (vm.user.stores === null || !vm.user.stores || !vm.user.stores.length)) {
                vm.errorAlert('A store must be assigned to this type of user.');
                vm.saving = false;
            } else {
                Users.create(vm.user).$promise.then(function (response) {
                    if (response.successMessage) {
                        vm.success = response.successMessage;
                        $state.go('admin.dashboard');
                    }
                }).catch(function (error) {
                    if (error.data.message) {
                        vm.errorAlert(error.data.message);
                    }
                    vm.saving = false;
                });
            }
        };

        vm.update = function () {
            if (!vm.user.roles || vm.user.roles.indexOf('user') > -1 && (vm.user.stores === null || !vm.user.stores || !vm.user.stores.length)) {
                vm.errorAlert('A store must be assigned to this type of user.');
                vm.saving = false;
            } else {
                Users.update(vm.user).$promise.then(function (response) {
                    if (response.errorMessage) {
                        vm.errorAlert(response.errorMessage);
                        vm.saving = false;
                    }
                    if (response.successMessage) {
                        vm.success = response.successMessage;
                        $state.go('users.view', { userId: vm.user._id });
                    }
                });
            }
        };

        vm.updateUserStatus = function () {
            vm.userStatusAlert(vm.user);
        };

        vm.getStores = function () {
            if (vm.storesLoaded === false) {
                vm.storesLoaded = true;
                Stores.resource.query().$promise.then(function (stores) {
                    vm.stores = stores;
                    var _iteratorNormalCompletion = true;
                    var _didIteratorError = false;
                    var _iteratorError = undefined;

                    try {
                        for (var _iterator = stores[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                            var store = _step.value;

                            vm.storeIdMap[store._id.toString()] = store;
                        }
                    } catch (err) {
                        _didIteratorError = true;
                        _iteratorError = err;
                    } finally {
                        try {
                            if (!_iteratorNormalCompletion && _iterator.return) {
                                _iterator.return();
                            }
                        } finally {
                            if (_didIteratorError) {
                                throw _iteratorError;
                            }
                        }
                    }
                });
            }
        };

        vm.validateOrganization = function (organization) {
            if (organization.userCreation === false) {
                vm.organizationAlert(organization);
            }

            /*vm.stores = _.filter(Stores, function(store){
                console.log(store.organization);
                //console.log(organization._id);
                return !_.includes(store.organization, organization._id);
            });*/
        };

        vm.editOrNah = function () {
            if (!vm.organizationEdit) {
                vm.state.go('users.edit', { userId: vm.user._id });
            } else {
                vm.organizationAlert(vm.user.organization);
            }
        };

        vm.organizationAlert = function (organization) {
            $mdDialog.show($mdDialog.alert().parent(angular.element(document.querySelector('#popupContainer'))).clickOutsideToClose(true).title('User create/edit Warning!').textContent(organization.name + ' users are not configured to be created or edited from inside the Call Center App. \nPlease contact your administrator with any questions.').ariaLabel('Organization Alert').ok('Got it!')).finally(function () {
                vm.user.organization = undefined;
            });
        };

        vm.userStatusAlert = function (user) {

            vm.showPrompt = function (ev) {
                // Appending dialog to document.body to cover sidenav in docs app

                var promptContent = {};
                if (user.status === 'active') {
                    promptContent.title = 'Inactivate User';
                    promptContent.textContent = 'Are you sure you want to inactivate ' + user.displayName + '?';
                    promptContent.ariaLabel = 'Inactivate Alert';
                    promptContent.ok = 'Inactivate';
                } else {
                    promptContent.title = 'Activate User';
                    promptContent.textContent = 'Are you sure you want to activate ' + user.displayName + '?';
                    promptContent.ariaLabel = 'Activate Alert';
                    promptContent.ok = 'Activate';
                }

                var confirm = $mdDialog.confirm().title(promptContent.title).textContent(promptContent.textContent).ariaLabel(promptContent.ariaLabel).targetEvent(ev).ok(promptContent.ok).cancel('Cancel');

                $mdDialog.show(confirm).then(function () {
                    console.log(user);
                    user.status = user.status === 'inactive' ? 'active' : 'inactive';
                    Users.update(user).$promise.then(function (response) {
                        console.log(response);
                    });
                }, function () {
                    console.log('Cancel');
                });
            };

            vm.showPrompt();
        };

        vm.errorAlert = function (error) {
            $mdDialog.show($mdDialog.alert().parent(angular.element(document.querySelector('#popupContainer'))).clickOutsideToClose(true).title('Error Creating User!').textContent(error).ariaLabel('User Error Alert').ok('Got it!')).finally(function () {});
        };

        vm.changePassword = function () {
            vm.showPrompt = function (ev) {
                // Appending dialog to document.body to cover sidenav in docs app
                var confirm = $mdDialog.prompt().title('Update User\'s Password').placeholder('New Password').ariaLabel('New Password').targetEvent(ev).ok('Update Password').cancel('Cancel');

                $mdDialog.show(confirm).then(function (newPassword) {
                    vm.user.newPassword = newPassword;
                    Users.changeUserPassword(vm.user).$promise.then(function (response) {
                        vm.success = response.message;
                    }, function (err) {
                        vm.error = err.data.message;
                    });
                }, function () {
                    console.log('Cancel');
                });
            };

            vm.showPrompt();
        };

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            vm.getStores();
        }

        function getPreviousState() {
            // console.log($rootScope.previousState.name);
            return $rootScope.previousState.name;
        };

        function getPreviousStateParams() {
            // console.log($rootScope.previousStateParams);
            return $rootScope.previousStateParams;
        };
    }
})();