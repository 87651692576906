'use strict';

(function () {
    angular.module('core').factory('NotificationService', NotificationService);

    NotificationService.$inject = [];

    function NotificationService() {

        this.new = function (header, body, image) {
            var title = header ? header : 'STM - Call Center';
            var icon = image ? image : 'modules/core/img/brand/STM_logo.png';
            if (window.Notification && Notification.permission !== 'denied') {
                new Notification(title, { body: body, icon: icon });
            }
        };

        return this;
    }
})();