'use strict';

(function () {
    // Store details controller
    angular.module('customers.roadside-service').controller('RoadsideServiceController', RoadsideServiceController);

    RoadsideServiceController.$inject = ['$scope', '$window', 'Authentication', 'Customers', '$mdDialog', 'SocketService', 'uiGridConstants', '$interval', '$timeout', '$mdToast', '$location', '$anchorScroll'];

    function RoadsideServiceController($scope, $window, Authentication, Customers, $mdDialog, SocketService, uiGridConstants, $interval, $timeout, $mdToast, $location, $anchorScroll) {
        var vm = this;
        vm.authentication = Authentication;
        vm.selectedRequest = null;
        vm.selectedRequestOriginal = null;
        vm.selectedRequestForm = {};
        vm.states = CallCenterConstants.US_STATES;
        vm.error = null;
        vm.loading = false;
        vm.viewState = 'OPEN'; // 'OPEN' or 'CLOSED'
        vm.$mdDialog = $mdDialog;
        vm.selectedRequestTab = vm.maddenCoTab;
        vm.updateRequestStatus = updateRequestStatus;
        vm.cancelDialog = cancelDialog;
        vm.rowFormatter = rowFormatter;
        vm.getRoadsideRequests = getRoadsideRequests;
        vm.confirmInfo = confirmInfo;
        vm.confirmEmails = confirmEmails;
        vm.addProblem = addProblem;
        vm.removeProblem = removeProblem;
        vm.toggleOpenCloseTabs = toggleOpenCloseTabs;
        vm.deleteRoadsideRequest = deleteRoadsideRequest;
        vm.generateMaddencoFormText = generateMaddencoFormText;
        vm.onCopiedMaddenco = onCopiedMaddenco;
        vm.maddencoFormText = '';
        vm.copyButtonText = 'Copy to Clipboard';
        vm.paginationOptions = {
            pageNumber: 1,
            pageSize: 20
        };

        vm.selectTireSize = selectTireSize;
        vm.selectTirePosition = selectTirePosition;
        vm.tireSizes = CallCenterConstants.COMMON_TIRE_SIZES;
        vm.truckTirePositions = CallCenterConstants.WHEEL_POSITIONS;
        vm.trailerTirePositions = _.filter(angular.copy(vm.truckTirePositions), function (tp) {
            return tp.type !== 'steer';
        });

        vm.infoTab = 0;
        vm.emailTab = 1;
        vm.maddenCoTab = 2;

        var spaceBar = 32;
        var enter = 13;
        var comma = 188;
        var semicolon = 186;
        vm.customKeys = [spaceBar, enter, comma, semicolon];

        function activate() {
            SocketService.on('roadside-service-request', function (msg) {
                if (vm.viewState === 'OPEN') {
                    getRoadsideRequests('OPEN');
                }

                if (msg.status === 'COMPLETE' && vm.selectedRequest && msg.data._id === vm.selectedRequest._id) {
                    deselectRoadsideRequest(msg.data);
                    showToast('Completed: ' + msg.data.companyName + ' - ' + msg.data.maddenCo.workOrder.orderNumber);
                }

                if (msg.status === 'FAILED' && vm.selectedRequest && msg.data._id === vm.selectedRequest._id) {
                    vm.selectedRequest = msg.data;
                    vm.selectedRequestOriginal = angular.copy(msg.data);
                    showToast('Error when running macro.');
                }
            });
        }

        function showToast(message) {
            return $mdToast.show($mdToast.simple().textContent(message).position('bottom right').hideDelay(5000));
        }

        function selectTirePosition(idx) {
            if (vm.selectedRequest.problems[idx].tirePositionOption === 'other') {
                vm.selectedRequestproblems[idx].tirePosition = '';
            } else {
                vm.selectedRequest.problems[idx].tirePosition = vm.selectedRequest.problems[idx].tirePositionOption;
            }
        }

        function selectTireSize(idx) {
            if (vm.selectedRequest.problems[idx].tireSizeOption === 'other') {
                vm.selectedRequest.problems[idx].tireSize = '';
            } else {
                vm.selectedRequest.problems[idx].tireSize = vm.selectedRequest.problems[idx].tireSizeOption;
            }
        }

        function addProblem() {
            var clonedProblem = angular.copy(vm.selectedRequest.problems[0]);
            delete clonedProblem._id;
            vm.selectedRequest.problems.push(clonedProblem);
            var newHash = 'problem' + (vm.selectedRequest.problems.length - 1);

            if ($location.hash() !== newHash) {
                $location.hash(newHash);
            } else {
                $anchorScroll();
            }
        }

        function removeProblem(idx) {
            vm.selectedRequest.problems.splice(idx, 1);
        }

        function resetPagination() {
            vm.paginationOptions = {
                pageNumber: 1,
                pageSize: 20
            };
        }
        function getRoadsideRequests(state) {
            var params = state === 'OPEN' ? { state: 'OPEN' } : { state: 'CLOSED' };
            params.pageNumber = vm.paginationOptions.pageNumber;
            params.pageSize = vm.paginationOptions.pageSize;
            Customers.query(params).$promise.then(function (response) {
                vm.gridOptions.totalItems = response.total;
                vm.gridOptions.data = response.data;

                var _iteratorNormalCompletion = true;
                var _didIteratorError = false;
                var _iteratorError = undefined;

                try {
                    var _loop = function _loop() {
                        var request = _step.value;

                        request.statusAndSource = {
                            status: request.status,
                            source: request.source ? request.source.name : ''
                        };
                        request.cssClass = rowFormatter(request);
                        var timer = $interval(function () {
                            var overdueClass = rowFormatter(request);
                            request.cssClass = overdueClass;

                            if (overdueClass && angular.isDefined(timer)) {
                                $interval.cancel(timer);
                                timer = undefined;
                            }
                        }, 10000);
                    };

                    for (var _iterator = vm.gridOptions.data[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                        _loop();
                    }
                } catch (err) {
                    _didIteratorError = true;
                    _iteratorError = err;
                } finally {
                    try {
                        if (!_iteratorNormalCompletion && _iterator.return) {
                            _iterator.return();
                        }
                    } finally {
                        if (_didIteratorError) {
                            throw _iteratorError;
                        }
                    }
                }
            });
        }

        function selectRoadsideRequest(roadsideRequest) {
            var requestIsLocked = roadsideRequest.lockedBy && roadsideRequest.lockedBy._id !== vm.authentication.user._id;
            if (requestIsLocked || vm.viewState === 'CLOSED') {
                vm.showRoadsideRequestDialog(roadsideRequest);
                return;
            }

            roadsideRequest.lockedBy = vm.authentication.user._id;
            updateRoadsideRequest(roadsideRequest).then(function (request) {
                vm.selectedRequestOriginal = angular.copy(request);
                vm.showRoadsideRequestDialog(request);
                vm.loading = false;
            }).catch(function (err) {
                console.error(err);
                vm.loading = false;
            });
        }

        function deselectRoadsideRequest(roadsideRequest) {
            roadsideRequest.lockedBy = null;
            updateRoadsideRequest(roadsideRequest).then(function (request) {
                vm.gridApi.selection.clearSelectedRows();
                vm.error = null;
                vm.selectedRequest = null;
                vm.selectedRequestOriginal = null;
                vm.loading = false;
                $mdDialog.hide();
            }).catch(function (err) {
                console.error(err);
                vm.loading = false;
                vm.error = err.data ? err.data.message : err.message;
            });
        }

        function updateRoadsideRequest(roadsideRequest) {
            vm.error = null;
            vm.loading = true;
            return Customers.update(roadsideRequest).$promise;
        }

        function deleteRoadsideRequest() {
            var shouldDelete = confirm('Are you sure you want to delete this service call? This cannot be undone. \n\n Click OK to delete.');
            if (shouldDelete) {
                vm.selectedRequest.status = 'DELETED';
                vm.selectedRequest.lockedBy = null;
                updateRoadsideRequest(vm.selectedRequest).then(function (request) {
                    vm.gridApi.selection.clearSelectedRows();
                    vm.error = null;
                    vm.selectedRequest = null;
                    vm.selectedRequestOriginal = null;
                    vm.loading = false;
                    $mdDialog.hide();
                }).catch(function (err) {
                    console.error(err);
                    vm.loading = false;
                    vm.error = err.data.message;
                });
            }
        }

        vm.gridOptions = {
            appScopeProvider: vm,
            columnDefs: [{
                name: 'Lock',
                field: 'lockedBy',
                width: '5%',
                cellClass: 'text-center',
                cellTemplate: '<div class="ui-grid-cell-contents" title="Locked By: {{COL_FIELD.displayName}}"><i ng-class="{\'fas fa-user-lock\': COL_FIELD }" aria-hidden="true"></i></div>'
            }, {
                name: 'Status',
                field: 'statusAndSource',
                cellTemplate: '<div class="ui-grid-cell-contents"><div class="status-name">{{COL_FIELD.status | prettySnake}}</div><div ng-if="COL_FIELD.source === \'webFormPilot\'" class="pilot-indicator">Pilot</div></div>'
            }, { name: 'Company', field: 'companyName' }, { name: 'Contact', field: 'contact.name' }, {
                name: 'Location',
                field: 'location',
                cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD.city}}, {{COL_FIELD.state}}</div>'
            }, { name: 'Reference #', field: 'referenceNumber' }, { name: 'Account #', field: 'accountNumber' }, {
                name: 'Created',
                field: 'created',
                sort: { direction: uiGridConstants.ASC, priority: 1 },
                cellTemplate: '<div class="ui-grid-cell-contents"><span title="{{COL_FIELD | date: \'EEE d - h:mm a\'}}" class="ui-grid-cell-contents" am-time-ago="COL_FIELD"></span></div>'
            }],
            data: [],
            enableHorizontalScrollbar: 0,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            enableColumnMenus: false,
            enableSorting: false,
            enableSelectAll: false,
            multiSelect: false,
            flatEntityAccess: false,
            paginationPageSize: 20,
            paginationPageSizes: [5, 10, 20],
            useExternalPagination: true,
            rowTemplate: rowTemplate(),
            onRegisterApi: function onRegisterApi(gridApi) {
                vm.gridApi = gridApi;
                vm.gridApi.selection.on.rowSelectionChanged(null, function (row, event) {
                    if (!event || !event.target || !event.target.href || event.target.href.indexOf('tel:') === -1) {
                        var selection = vm.gridApi.selection.getSelectedRows();
                        selectRoadsideRequest(selection[0]);
                    }
                });

                vm.gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
                    vm.paginationOptions.pageNumber = newPage;
                    vm.paginationOptions.pageSize = pageSize;
                    vm.getRoadsideRequests(vm.viewState);
                });
            }
        };

        function rowTemplate() {
            return '<div ui-grid-cell\n            ng-class="row.entity.cssClass"\n            ng-click="grid.appScope.fnOne(row)"\n            ng-repeat="col in colContainer.renderedColumns"\n            class="ui-grid-cell ">\n            </div>';
        }

        function rowFormatter(request) {
            if (vm.noFormatting || vm.viewState === 'CLOSED') {
                return '';
            }
            var minOld = moment().diff(request.created, 'minutes');

            var cssClass = minOld < 5 ? '' : 'overdue';
            return cssClass;
        }

        function toggleOpenCloseTabs(state) {
            resetPagination();
            vm.viewState = state;
            vm.getRoadsideRequests(state);
        }

        function getEmailRecipients(request) {
            if (request.emailRecipients.length > 0) {
                return request.emailRecipients;
            }

            return request.contact.email ? [request.contact.email] : [];
        }

        vm.showRoadsideRequestDialog = function (request) {
            vm.selectedRequest = request;
            vm.selectedRequestParseErrors = request.source.parseError ? request.source.parseError.split('\r\n') : [];
            vm.selectedRequest.emailRecipients = getEmailRecipients(request);

            if (vm.selectedRequest.status === 'INFO_VERIFIED' || vm.selectedRequest.status === 'FAILED') {
                vm.selectedRequestTab = vm.maddenCoTab;
            } else {
                vm.selectedRequestTab = vm.infoTab;
            }

            // Generate maddenco text if needed
            if (request.status !== 'NEW') {
                generateMaddencoFormText(request);
            }
            var dialogOptions = {
                templateUrl: 'modules/customers/features/roadside-service/roadside-service-modal.client.view.html',
                targetEvent: event,
                parent: angular.element(document.body),
                bindToController: true,
                controllerAs: 'vm',
                clickOutsideToClose: true,
                fullscreen: false,
                controller: function controller() {
                    return vm;
                },
                locals: { selectedRequestForm: vm.selectedRequestForm }
            };

            $mdDialog.show(dialogOptions).then(function () {
                // mdDialog.hide was called
            }, function (err) {
                // console.error(err) already posted by show()
                cancelDialog();
            });
        };

        function confirmInfo() {
            if (!vm.selectedRequest.accountNumber) {
                vm.error = 'Account Number is required.';
                return;
            }

            vm.error = null;
            vm.selectedRequest.status = 'CHECK_EMAIL_INFO';
            vm.selectedRequestTab = vm.emailTab;
        }

        function confirmEmails() {
            var request = angular.copy(vm.selectedRequest);
            request.status = 'INFO_VERIFIED';
            updateRoadsideRequest(request).then(function (r) {
                vm.selectedRequest = r;
                vm.selectedRequestOriginal = angular.copy(r);
                vm.selectedRequestTab = vm.maddenCoTab;
                generateMaddencoFormText(r);
                vm.loading = false;
            }).catch(function (err) {
                console.error(err);
                vm.loading = false;
                vm.error = err.data.message;
            });
        }

        function onCopiedMaddenco() {
            vm.copyButtonText = 'Copied!';
            $timeout(function () {
                vm.copyButtonText = 'Copy to Clipboard!';
            }, 3000);
        }

        function updateRequestStatus(newStatus) {
            if (newStatus) {
                vm.selectedRequest.status = newStatus;
            }

            deselectRoadsideRequest(vm.selectedRequest);
        }

        function generateMaddencoFormText(request) {
            var notes = request.notes.replace(/\r/g, '').replace(/\n/g, ' ');
            var street1 = request.location.street.replace(/\r/g, '').replace(/\n/g, ' ');
            var tirePositionsStr = '';
            var truckNumbersStr = '';
            var trailerNumbersStr = '';
            var tireSizeStr = '';
            var wheelOKStr = '';
            var tirePreferenceSteer = '';
            var tirePreferenceDrive = '';
            var tirePreferenceTrailer = '';
            /* Truck and Trailer Numbers */
            var truckNumbers = request.problems.map(function (p) {
                if (p.truckNumber) {
                    return p.problemArea === 'truck' ? p.truckNumber + '*' : p.truckNumber;
                }
            }).sort(function (a, b) {
                return b.length - a.length;
            });
            var trailerNumbers = request.problems.map(function (p) {
                if (p.trailerNumber) {
                    return p.problemArea === 'trailer' ? p.trailerNumber + '*' : p.trailerNumber;
                }
            }).sort(function (a, b) {
                return b.length - a.length;
            });

            var uniqueTruckNumbers = [];
            var uniqueTrailerNumbers = [];

            truckNumbers.forEach(function (n) {
                if (n && !uniqueTruckNumbers.includes(n) && !uniqueTruckNumbers.includes(n + '*')) {
                    uniqueTruckNumbers.push(n);
                }
            });

            trailerNumbers.forEach(function (n) {
                if (n && !uniqueTrailerNumbers.includes(n) && !uniqueTrailerNumbers.includes(n + '*')) {
                    uniqueTrailerNumbers.push(n);
                }
            });

            var tirePositionMap = {};
            var someWheelsNotOK = false;
            request.problems.forEach(function (p, idx) {
                var delimiter = idx + 1 < request.problems.length ? ', ' : '';

                // Create tire position map.
                if (!tirePositionMap[p.tirePosition]) {
                    tirePositionMap[p.tirePosition] = 1;
                } else {
                    tirePositionMap[p.tirePosition] += 1;
                }

                tireSizeStr += '' + p.tireSize + delimiter;

                // Set tire preference
                if (request.tirePositionOption === 'other') {
                    tirePreferenceSteer += request.tireFunction === 'steer' && !tirePreferenceSteer.includes(p.tirePreference) ? p.tirePreference : '';
                    tirePreferenceDrive += request.tireFunction === 'drive' && !tirePreferenceDrive.includes(p.tirePreference) ? p.tirePreference : '';
                    tirePreferenceTrailer += request.tireFunction === 'trailer' && !tirePreferenceTrailer.includes(p.tirePreference) ? p.tirePreference : '';
                } else {
                    if ((p.tirePositionOption === 'LS' || p.tirePositionOption === 'RS') && !tirePreferenceSteer.includes(p.tirePreference)) {
                        tirePreferenceSteer += p.tirePreference;
                    } else {
                        tirePreferenceDrive += p.problemArea === 'truck' && !tirePreferenceDrive.includes(p.tirePreference) ? p.tirePreference : '';
                        tirePreferenceTrailer += p.problemArea === 'trailer' && !tirePreferenceTrailer.includes(p.tirePreference) ? p.tirePreference : '';
                    }
                }

                if (!p.wheelOK) {
                    someWheelsNotOK = true;
                }
                wheelOKStr += '' + (p.wheelOK ? 'YES' : 'NO') + (p.wheelRequestDetails ? '-' + p.wheelRequestDetails : '') + (idx === request.problems.length - 1 ? '' : ',');
            });

            truckNumbersStr = uniqueTruckNumbers.join(', ');
            trailerNumbersStr = uniqueTrailerNumbers.join(', ');

            /* Create tire position str */
            var keyCount = 0;
            for (var key in tirePositionMap) {
                keyCount++;
                var delimiter = keyCount < Object.keys(tirePositionMap).length ? ', ' : '';

                if (tirePositionMap[key]) {
                    tirePositionsStr += key + ' ' + delimiter;
                }
            }
            tirePositionsStr += ' (' + request.problems.length + ')';

            /***************************
             ***************************
             * DO NOT EDIT THIS STRING UNLESS YOU KNOW WHAT YOUR DOING
             ***************************
             ***************************/
            vm.maddencoFormText = 'ID#:' + request._id + '\nMaddenCo#:' + (request.source && request.source.name === 'webFormPilot' ? '743' : '212') + '\nSalesman#:' + vm.authentication.user.employeeID + '\nPO#:' + (request.purchaseOrderNumber || '') + '\nCustomer#:' + request.accountNumber + '\n' + notes + '\n\n' + request.contact.name + '\n' + request.contact.phone + '\n\n' + request.location.city + ', ' + request.location.state + '\n*\n' + street1 + '\n\n\n' + truckNumbersStr + '\n' + trailerNumbersStr + '\n' + tirePositionsStr + '\n' + tireSizeStr + '\n' + tirePreferenceSteer + '\n' + tirePreferenceDrive + '\n' + tirePreferenceTrailer + '\nEMAIL\n' + (someWheelsNotOK ? wheelOKStr.trim() : 'YES') + '\n' + request.driver.name + '\n' + request.driver.phone + '\n' + (request.referenceNumber || '') + '\n' + (request.purchaseOrderNumber || '') + '\n' + request.purchaseOrderContact.name + ' - ' + request.purchaseOrderContact.phone + '\n\n\n\n\n\n\n\n\n';
        }

        function cancelDialog() {
            if (vm.selectedRequestOriginal) {
                deselectRoadsideRequest(vm.selectedRequestOriginal);
            }
            vm.gridApi.selection.clearSelectedRows();
        }

        // Unlock request when user leaves app.
        $window.onbeforeunload = function () {
            cancelDialog();
        };

        // Unlock request when user reroutes.
        $scope.$on('$destroy', function () {
            cancelDialog();
        });
        activate();
    }
})();