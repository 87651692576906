'use strict';

(function () {
    angular.module('stores').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('stores', {
            abstract: true,
            url: '/stores',
            template: '<ui-view/>',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('stores.list', {
            url: '',
            templateUrl: 'modules/stores/features/stores-crud/views/stores-list.client.view.html',
            controller: 'StoresListController',
            controllerAs: 'vm',
            resolve: {
                storeListResolve: getStoreList
            },
            data: {
                pageTitle: 'Stores List'
            }
        }).state('stores.create', {
            url: '/create',
            templateUrl: 'modules/stores/features/stores-crud/views/stores-form.client.view.html',
            controller: 'StoresCRUDController',
            controllerAs: 'vm',
            resolve: {
                storeResolve: newStore
            },
            data: {
                pageTitle: 'Stores Create',
                roles: ['admin', 'developer', 'organizationHead'] //We may have other roles that can create stores, I just made up manager as a placeholder
            }
        }).state('stores.edit', {
            url: '/:storeId/edit',
            templateUrl: 'modules/stores/features/stores-crud/views/stores-form.client.view.html',
            controller: 'StoresCRUDController',
            controllerAs: 'vm',
            resolve: {
                storeResolve: getStore
            },
            data: {
                pageTitle: 'Edit Store {{ storeResolve.name }}'
            }
        }).state('stores.view', {
            url: '/:storeId',
            templateUrl: 'modules/stores/features/stores-crud/views/stores-view.client.view.html',
            controller: 'StoresCRUDController',
            controllerAs: 'vm',
            resolve: {
                storeResolve: getStore
            },
            data: {
                pageTitle: 'Store {{ articleResolve.name }}'
            }
        });
    }

    getStore.$inject = ['$stateParams', 'Stores'];
    function getStore($stateParams, Stores) {
        return Stores.resource.get({
            storeId: $stateParams.storeId
        }).$promise;
    }

    getStoreList.$inject = ['Stores'];
    function getStoreList(Stores) {
        return Stores.resource.query().$promise;
    }

    newStore.$inject = ['Stores'];
    function newStore(Stores) {
        return new Stores.resource(); //eslint-disable-line new-cap
    }
})();