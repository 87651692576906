'use strict';

(function () {
    angular.module('stores.store-details').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('store-details', {
            url: '/store/{storeId:[a-z0-9]{24}}/details',
            // templateUrl: 'modules/stores/features/store-details/store-details.client.view.html',
            // controller: 'StoreDetailsController',
            // controllerAs: 'storeDetails',
            resolve: {
                storeResolve: getStore
            },
            data: {
                pageTitle: 'Edit Store'
            },
            views: {
                '': {
                    templateUrl: 'modules/stores/features/store-details/store-details.client.view.html',
                    controller: 'StoreDetailsController',
                    controllerAs: 'storeDetails'
                },
                'schedule@store-details': {
                    templateUrl: 'modules/stores/features/store-details/schedule/store-details.schedule.client.view.html',
                    controller: 'StoreDetailsScheduleController',
                    controllerAs: 'storeSchedule'
                },
                'directory@store-details': {
                    templateUrl: 'modules/stores/features/store-details/directory/store-details.directory.client.view.html',
                    controller: 'StoreDetailsDirectoryController',
                    controllerAs: 'storeDirectory'
                },
                'fleet-check@store-details': {
                    templateUrl: 'modules/stores/features/store-details/fleet-check/fleet-check.client.view.html',
                    controller: 'StoreDetailsFleetCheckController',
                    controllerAs: 'storeFleetCheck'
                }
            }
        });
    }

    getStore.$inject = ['$stateParams', 'Stores', '$q'];

    function getStore($stateParams, Stores) {
        return Stores.resource.get({
            storeId: $stateParams.storeId
        }).$promise;
    }

    newStore.$inject = ['Stores'];

    function newStore(Stores) {
        return new Stores();
    }
})();