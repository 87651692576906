'use strict';

(function () {
    angular.module('core.header').controller('HeaderController', HeaderController);

    //dependency injections for the controller go here
    HeaderController.$inject = ['$state', 'Menus', 'Authentication', 'DetailsEditService', 'Customers', 'SocketService', 'Users', 'NotificationService'];

    function HeaderController($state, Menus, Authentication, DetailsEditService, Customers, SocketService, Users, NotificationService) {

        /*jshint validthis: true */
        var vm = this;
        vm.state = $state;
        vm.roadsideRequests = [];
        vm.pulseBadge = false;

        vm.isSaveChangesWarningViewable = DetailsEditService.isSaveChangesWarningViewable;
        vm.navigateIfAllowed = DetailsEditService.navigateIfAllowed;

        //bindable members go here
        vm.authentication = Authentication;
        vm.isCollapsed = false;
        vm.menu = Menus.getMenu('topbar');
        vm.toggleCollapsibleMenu = toggleCollapsibleMenu;
        vm.enableUserNotifications = enableUserNotifications;
        vm.showPageNumbers = true;
        var originatorEv; //eslint-disable-line no-unused-vars
        vm.currentPage = 0;

        if (Authentication.user && Authentication.user.roles && (Authentication.user.roles[0] === 'developer' || Authentication.user.roles[0] === 'admin' || Authentication.user.roles[0] === 'organizationHead')) {
            vm.isAdmin = true;
        }
        if (Authentication.user && Authentication.user.roles && (Authentication.user.roles[0] === 'developer' || Authentication.user.roles[0] === 'admin') && Authentication.user.organization && Authentication.user.organization.name === 'Southern Tire Mart') {
            vm.roadsideAssistanceAccess = true;
        }

        vm.openMenu = function ($mdOpenMenu, ev) {
            originatorEv = ev;
            $mdOpenMenu(ev);
        };

        activate();

        function activate() {
            if (vm.roadsideAssistanceAccess) {
                getRoadsideRequests();
                SocketService.on('roadside-service-request', function (msg) {
                    getRoadsideRequests();
                    if (!msg.data.updatedBy) {
                        roadsideRequestNotification();
                    }
                });
            }
        }

        function toggleCollapsibleMenu() {
            vm.isCollapsed = !vm.isCollapsed;
        }

        function getRoadsideRequests() {
            Customers.query({ state: 'OPEN' }).$promise.then(function (response) {
                vm.roadsideRequests = response.data;
                pulseBadge(response.data);
            });
        }

        function pulseBadge(requests) {
            vm.pulseBadge = false;
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = requests[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var request = _step.value;

                    if (!request.updatedBy) {
                        vm.pulseBadge = true;
                    }
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        }

        function enableUserNotifications() {
            if (vm.authentication.user.allowBrowserNotifications) {
                Notification.requestPermission(function (status) {
                    // status is "granted", if accepted by user
                    if (status === 'granted') {
                        Users.update(vm.authentication.user);
                    } else {
                        vm.authentication.user.allowBrowserNotifications = false;
                    }
                });
            } else {
                Users.update(vm.authentication.user);
            }
        }

        function roadsideRequestNotification() {
            NotificationService.new(null, 'A New Roadside Service Request has been received.');
            var audio = new Audio('modules/core/sounds/notification.mp3');
            audio.play();
        }
    }
})();