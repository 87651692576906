'use strict';

(function () {
    // Provides list of stores on the "naughty" list
    angular.module('stores').factory('NaughtyListService', naughtyListService);

    naughtyListService.$inject = ['$resource'];

    function naughtyListService($resource) {
        var naughtyListResource = $resource('stores/naughty', {}, {
            get: {
                method: 'GET',
                isArray: true
            }
        });

        var naughtyListSvc = {
            getNaughtyList: getNaughtyList
        };

        return naughtyListSvc;

        function getNaughtyList(includeInactive) {
            return naughtyListResource.get({ now: moment().startOf('day').format('YYYY-MM-DD'), includeInactive: includeInactive });
        }
    }
})();