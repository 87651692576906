'use strict';

(function () {
    angular.module('core').directive('stateSelectInator', stateSelectInator);

    function stateSelectInator() {
        var directive = {
            restrict: 'EA',
            scope: { className: '@', ngModel: '=', abbreviations: '=' },
            templateUrl: 'modules/core/directives/state-select-inator/state-select-inator.client.view.html',
            require: ['ngModel'],
            controller: StateSelectCtrl,
            controllerAs: 'stateSelect',
            replace: true
        };

        return directive;
    }

    StateSelectCtrl.$inject = ['$scope'];

    function StateSelectCtrl($scope) {
        var vm = this;
        vm.abbreviations = Boolean($scope.abbreviations || false);
        vm.className = $scope.className;
        vm.ngModel = $scope.ngModel;
        vm.states = CallCenterConstants.US_STATES;
    }
})();