'use strict';

(function () {
    // Sets keeps track of the edit mode for the entire store details page
    angular.module('core').factory('SocketService', SocketService);

    SocketService.$inject = [];

    function SocketService() {
        var socket = __socket;
        return socket;
        // return {};
    }
})();