'use strict';

(function () {
    // Store details controller
    angular.module('stores.store-details').directive('oncallWidgetInator', oncallWidgetInator);

    function oncallWidgetInator() {

        var directive = {
            restrict: 'AE',
            link: link
        };

        function link($scope, element, attrs) {
            var h = 0;
            // $scope.$watch(function() {
            //     h = element[0].children[0].offsetHeight;
            //     $scope.widgetHeight = {
            //         height: h
            //     };
            //     console.log('New height : ' + h);
            // });
            h = element[0].children[0].offsetHeight;
            $scope.widgetHeight = {
                height: h
            };
        }

        return directive;
    }
})();

// app.directive('master',function () { //declaration; identifier master
//     function link(scope, element, attrs) { //scope we are in, element we are bound to, attrs of that element
//         scope.$watch(function(){ //watch any changes to our element
//             scope.style = { //scope variable style, shared with our controller
//                 height:element[0].offsetHeight+'px', //set the height in style to our elements height
//                 width:element[0].offsetWidth+'px' //same with width
//             };
//         });
//     }
//     return {
//         restrict: 'AE', //describes how we can assign an element to our directive in this case like <div master></div
//         link: link // the function to link to our element
//     };
// });