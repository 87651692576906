'use strict';

(function () {
    // Keeps track of current tab index in Admin Dashboard
    angular.module('admin').factory('AdminDashboardTabsService', AdminDashboardTabsService);

    AdminDashboardTabsService.$inject = [];

    function AdminDashboardTabsService() {
        var currentTabIndex = 0;
        var previousTabIndex = 0;

        var svc = {
            getCurrentTabIndex: getCurrentTabIndex,
            getPreviousTabIndex: getPreviousTabIndex,
            setCurrentTabIndex: setCurrentTabIndex
        };

        return svc;

        function getCurrentTabIndex() {
            return currentTabIndex;
        }

        function getPreviousTabIndex() {
            return previousTabIndex;
        }

        function setCurrentTabIndex() {
            currentTabIndex = 0;
        }
    };
})();