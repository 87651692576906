'use strict';

//Stores service used to communicate Stores REST endpoints
(function () {
    angular.module('stores').factory('Stores', Stores);

    Stores.$inject = ['$resource', '$rootScope'];

    function Stores($resource, $rootScope) {
        var storeResource = $resource('stores/:storeId', {
            storeId: '@_id'
        }, {
            update: {
                method: 'PUT'
            }
        });

        function updateStore(storeId, newStore, success, err) {
            if (!storeId || storeId == null) {
                // eslint-disable-line eqeqeq
                storeId = newStore._id.toString();
            }

            function successWrapper(r) {
                $rootScope.$broadcast('store-update', { store: r });
                if (success) {
                    success(r);
                }
            }

            storeResource.update({ storeId: storeId }, newStore, successWrapper, err);
        }

        var svc = {
            resource: storeResource,
            updateStore: updateStore
        };

        return svc;
    }
})();