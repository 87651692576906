'use strict';

(function () {
    // Organizations List controller
    angular.module('organizations.crud').controller('OrganizationsListController', OrganizationsListController);

    OrganizationsListController.$inject = ['organizationListResolve', 'Authentication', '$state'];

    function OrganizationsListController(organizations, Authentication, $state) {

        /*jshint validthis: true */
        var vm = this;
        vm.state = $state;

        //bindable members go here
        vm.organizations = organizations;
        vm.error = null;
        vm.remove = remove;
        vm.authentication = Authentication;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {}

        // Remove existing Organization
        function remove() {
            if (confirm('Are you sure you want to delete?')) {
                return;
            }
        }
    }
})();