'use strict';

(function () {
    //Menu service used for managing  menus
    angular.module('core').factory('Menus', menuService);

    menuService.$inject = [];

    function menuService() {
        var svc = {};
        svc.defaultRoles = ['user'];
        svc.menus = {};
        svc.validateMenuExistance = validateMenuExistance;
        svc.getMenu = getMenu;
        svc.addMenu = addMenu;
        svc.removeMenu = removeMenu;
        svc.addMenuItem = addMenuItem;
        svc.removeMenuItem = removeMenuItem;
        svc.isPublic = false;

        activate();

        return svc;
        function activate() {
            //Adding the topbar menu
            addMenu('topbar');
        }

        // A private function for rendering decision
        function shouldRender(user) {
            if (user) {
                for (var userRoleIndex in user.roles) {
                    for (var roleIndex in svc.roles) {
                        if (svc.roles[roleIndex] === user.roles[userRoleIndex]) {
                            return true;
                        }
                    }
                }
            } else {
                return svc.isPublic;
            }

            return false;
        }

        // Validate menu existance
        function validateMenuExistance(menuId) {
            if (menuId && menuId.length) {
                if (svc.menus[menuId]) {
                    return true;
                } else {
                    throw new Error('Menu does not exist');
                }
            } else {
                throw new Error('MenuId was not provided');
            }
        }

        // Get the menu object by menu id
        function getMenu(menuId) {
            // Validate that the menu exists
            validateMenuExistance(menuId);

            // Return the menu object
            return svc.menus[menuId];
        }

        // Add new menu object by menu id
        function addMenu(menuId, isPublic, roles) {
            // Create the new menu
            svc.menus[menuId] = {
                isPublic: isPublic || false,
                roles: roles || svc.defaultRoles,
                items: [],
                shouldRender: shouldRender
            };

            // Return the menu object
            return svc.menus[menuId];
        }

        // Remove existing menu object by menu id
        function removeMenu(menuId) {
            // Validate that the menu exists
            validateMenuExistance(menuId);

            // Return the menu object
            delete svc.menus[menuId];
        }

        // Add menu item object
        function addMenuItem(menuId, menuItemTitle, menuItemURL, menuItemUIRoute, isPublic, roles) {
            // Validate that the menu exists
            validateMenuExistance(menuId);

            // Push new menu item
            svc.menus[menuId].items.push({
                title: menuItemTitle,
                link: menuItemURL,
                uiRoute: menuItemUIRoute || '/' + menuItemURL,
                isPublic: isPublic || svc.menus[menuId].isPublic,
                roles: roles || svc.defaultRoles,
                shouldRender: shouldRender
            });

            // Return the menu object
            return svc.menus[menuId];
        }

        // Remove existing menu object by menu id
        function removeMenuItem(menuId, menuItemURL) {
            // Validate that the menu exists
            validateMenuExistance(menuId);

            // Search for menu item to remove
            for (var itemIndex in svc.menus[menuId].items) {
                if (svc.menus[menuId].items[itemIndex].link === menuItemURL) {
                    svc.menus[menuId].items.splice(itemIndex, 1);
                }
            }

            // Return the menu object
            return svc.menus[menuId];
        }
    }
})();