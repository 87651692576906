'use strict';

(function () {
    angular.module('dev.authentication').controller('DevAuthenticationController', DevAuthenticationController);

    //dependency injections for the controller go here
    DevAuthenticationController.$inject = ['$http', '$location', 'Authentication', '$window'];

    function DevAuthenticationController($http, $location, Authentication, $window) {

        /*jshint validthis: true */
        var vm = this;

        //bindable members go here
        vm.authentication = Authentication;
        vm.signup = signup;
        vm.signin = signin;
        vm.error = null;
        vm.credentials = null;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {}

        function signup() {
            $http.post('/auth/devSignup', vm.credentials).success(function (response) {
                vm.authentication.user = response;
                $location.path('/');
            }).error(function (response) {
                vm.error = response.message;
            });
        }

        function signin() {
            $http.post('/auth/signin', vm.credentials).success(function (response) {
                vm.authentication.user = response;
                //kick user back out if they aren't a developer, they should have used keycloak login
                if (vm.authentication.user.roles.indexOf('developer') === -1) {
                    $window.location.href = '/api/auth/openidconnect';
                } else {
                    $location.path('home');
                }
            }).error(function (response) {
                vm.error = response.message;
            });
        }
    }
})();