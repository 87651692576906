'use strict';

(function () {

  angular.module('core').filter('convertMilitaryTime', ConvertMilitaryTime);

  ConvertMilitaryTime.$inject = [];

  function ConvertMilitaryTime() {
    return function (input) {
      if (!input || !input.length) {
        return input;
      }
      var isMilitary = input.length === 4 && /^\d+$/.test(input);
      if (isMilitary) {
        var hours = Number(input.substr(0, 2));
        if (hours < 0 || hours > 23) {
          return input;
        }
        var minutes = Number(input.substr(2, 2));
        if (minutes < 0 || minutes > 59) {
          return input;
        }
        var ampm = 'AM';
        if (hours >= 12) {
          ampm = 'PM';
          if (hours > 12) {
            hours = hours - 12;
          }
        }
        var minutesString = minutes < 10 ? '0' + minutes : minutes.toString();
        return hours + ':' + minutesString + ' ' + ampm;
      } else {
        return input;
      }
    };
  }
})();