'use strict';

(function () {
    // Setting up route
    angular.module('users.settings').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        // Users settings state routing
        $stateProvider.state('users', {
            abstract: true,
            url: '/users',
            template: '<ui-view/>',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('users.list', {
            url: '',
            templateUrl: 'modules/users/features/admin/views/users-list.client.view.html',
            controller: 'UsersListController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'Users List'
            }
        }).state('users.create', {
            url: '/create',
            templateUrl: 'modules/users/features/admin/views/user-edit.client.view.html',
            controller: 'UsersController',
            controllerAs: 'vm',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            },
            resolve: {
                organizations: getOrganizationList,
                UserResolve: newUser
            }
        }).state('users.edit', {
            url: '/:userId/edit',
            templateUrl: 'modules/users/features/admin/views/user-edit.client.view.html',
            controller: 'UsersController',
            controllerAs: 'vm',
            resolve: {
                UserResolve: ['Users', '$stateParams', function (Users, $stateParams) {
                    return Users.userById({ userId: $stateParams.userId }).$promise;
                }],
                organizations: getOrganizationList
            }

        }).state('users.view', {
            url: '/:userId',
            templateUrl: 'modules/users/features/admin/views/user-view.client.view.html',
            controller: 'UsersController',
            controllerAs: 'vm',
            resolve: {
                UserResolve: ['Users', '$stateParams', function (Users, $stateParams) {
                    return Users.userById({ userId: $stateParams.userId }).$promise;
                }],
                organizations: getOrganizationList
            }

        });
    }

    getOrganizationList.$inject = ['Organizations'];
    function getOrganizationList(Organizations) {
        return Organizations.query().$promise;
    }

    newUser.$inject = ['Users'];
    function newUser(Organizations) {
        return new Organizations();
    }
})();