'use strict';

//Organizations service used to communicate Organizations REST endpoints
(function () {
    angular.module('organizations').factory('Organizations', Organizations);

    Organizations.$inject = ['$resource', '$http'];

    function Organizations($resource, $http) {
        var svc = $resource('organizations/:organizationId', {
            organizationId: '@_id'
        }, {
            update: {
                method: 'PUT'
            }
        });

        svc.getLogo = function (organizationId) {
            return $http.get('organizations/' + organizationId + '/logo').then(function (response) {
                return response.data;
            }).catch(function (err) {
                console.error('error getting organization');
            });
        };

        return svc;
    }
})();