'use strict';

(function () {
    angular.module('stores.store-search').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('store-search', {
            url: '/stores/search',
            templateUrl: 'modules/stores/features/store-search/store-search.client.view.html',
            controller: 'StoreSearchController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'Store Search',
                roles: ['*']
            }
        });
    }
})();