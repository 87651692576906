'use strict';

/* globals _ */
(function () {
    angular.module('users').controller('UsersListController', UsersListController);

    //dependency injections for the controller go here
    UsersListController.$inject = ['Authentication', '$state', 'users', 'organizationListResolve'];

    function UsersListController(Authentication, $state, users, organizations) {

        var vm = this;
        vm.state = $state;
        //bindable members go here
        vm.user = Authentication.user;
        vm.success = null;
        vm.error = null;
        vm.newUser = {};
        vm.isDeveloper = _.includes(Authentication.user.roles, 'developer');
        vm.status = 'active';

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            vm.users = users;
            var organizationMap = {};
            organizations.forEach(function (org) {
                organizationMap[org._id.toString()] = org;
            });
            if (vm.isDeveloper === true) {
                vm.users = users;
            } else {
                vm.users = _.filter(users, function (user) {
                    return !_.includes(user.provider, 'dev');
                });
            }
            vm.users.forEach(function (u) {
                if (u.organization && organizationMap[u.organization._id.toString()]) {
                    u.organization = organizationMap[u.organization._id.toString()];
                }
            });
        }
    }
})();