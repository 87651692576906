'use strict';

(function () {
    // Config HTTP Error Handling
    angular.module('core').config(httpConfig);

    httpConfig.$inject = ['$httpProvider'];

    function httpConfig($httpProvider) {
        // Set the httpProvider "not authorized" interceptor
        $httpProvider.interceptors.push(interceptor);
        interceptor.$inject = ['$q', '$location', 'Authentication'];
        function interceptor($q, $location, Authentication) {
            return {
                response: function response(_response) {
                    return _response || $q.when(_response);
                },
                responseError: function responseError(rejection) {
                    switch (rejection.status) {
                        case 401:
                            // Deauthenticate the global user
                            Authentication.user = null;

                            // Redirect to signin page
                            $location.path('/');
                            break;
                        case 403:
                            // Add unauthorized behaviour
                            break;
                    }

                    return $q.reject(rejection);
                }
            };
        }
    }
})();