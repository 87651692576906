'use strict';

// Init the application configuration module for AngularJS application

var ApplicationConfiguration = function () {
    // eslint-disable-line no-unused-vars
    // Init module configuration options
    var applicationModuleName = 'collector-portal';

    var applicationModuleVendorDependencies = ['ngResource', 'ngCookies', 'ngAnimate', 'ngAria', 'ngSanitize', 'ui.router', 'ui.bootstrap', 'ui.utils', 'ngMaterial', 'ui.grid', 'ui.grid.selection', 'ui.grid.edit', 'ui.grid.pagination', 'textAngular', 'ngImgCrop', 'ngFileUpload', 'ui.bootstrap.showErrors', 'uiGmapgoogle-maps.wrapped', 'uiGmapgoogle-maps.providers', 'ngMessages', 'ui.mask', 'angularMoment', 'ngclipboard'];

    // Add a new vertical module
    var registerModule = function registerModule(moduleName) {

        // Create angular module
        angular.module(moduleName, []);

        // Add the module to the AngularJS configuration file
        angular.module(applicationModuleName).requires.push(moduleName);
    };

    var registerFeatureModule = function registerFeatureModule(parentModuleName, featureModuleName) {

        //Create angular module for the feature
        angular.module(featureModuleName, []);

        //Add the module as a dependency to the parent module
        angular.module(parentModuleName).requires.push(featureModuleName);
    };

    return {
        applicationModuleName: applicationModuleName,
        applicationModuleVendorDependencies: applicationModuleVendorDependencies,
        registerModule: registerModule,
        registerFeatureModule: registerFeatureModule
    };
}();