'use strict';

/* globals _ */
(function () {
    // Setting up route
    angular.module('core').config(routeConfig);

    routeConfig.$inject = ['$stateProvider', '$urlRouterProvider'];

    function routeConfig($stateProvider, $urlRouterProvider) {
        // dashboard is home page
        // note: if not logged in, you will be redirected to 'signin' state, this behavior is defined in application.js
        $urlRouterProvider.when('/', '/home');
        $urlRouterProvider.when('', '/home');

        // Redirect to page not found
        $urlRouterProvider.otherwise('/page-not-found');

        // Home state routing
        $stateProvider.state('home', {
            url: '/home',
            controller: HomeController,
            params: {
                inGlobalEdit: false
            }
        }).state('page-not-found', {
            url: '/page-not-found',
            templateUrl: 'modules/core/views/page-not-found.client.view.html'
        }).state('not-authorized', {
            url: '/not-authorized',
            templateUrl: 'modules/core/views/not-authorized.client.view.html'
        });
    }

    HomeController.$inject = ['Authentication', '$state'];
    function HomeController(Authentication, $state) {
        if (Authentication.user && Authentication.user.roles) {
            var adminRoles = ['developer', 'admin', 'organizationHead'],
                isAdmin = _.intersection(adminRoles, Authentication.user.roles).length > 0;
            var storeRoles = ['user', 'store'],
                isStoreUser = _.intersection(storeRoles, Authentication.user.roles).length > 0;

            if (isAdmin === true) {
                $state.go('store-search');
            } else if (isStoreUser === true) {
                var storeIds = Authentication.user.stores;
                if (storeIds.length === 1) {
                    $state.go('store-details', { storeId: Authentication.user.stores });
                } else if (storeIds.length > 0) {
                    $state.go('store-search');
                } else {
                    alert('Your store has not been set up. Please contact the call center for help');
                }
            } else {
                $state.go('not-authorized');
            }
        } else {
            $state.go('not-authorized');
        }
    }
})();