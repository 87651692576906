'use strict';

(function () {
    // Users service used for communicating with the users REST endpoint
    angular.module('users').factory('Users', userService);

    userService.$inject = ['$resource'];

    function userService($resource) {
        var svc = $resource('users', {}, {
            update: {
                method: 'PUT'
            },
            deleteSocialAccount: {
                url: 'users/accounts',
                method: 'DELETE'
            },
            create: {
                url: '/auth/signup',
                method: 'POST'
            },
            changePassword: {
                url: 'users/password',
                method: 'POST'
            },
            changeUserPassword: {
                url: 'users/userPassword',
                method: 'POST'
            },
            list: {
                url: 'users/list',
                method: 'GET',
                isArray: true
            },
            userById: {
                url: '/user/userById',
                method: 'POST'
            }
        });

        return svc;
    }
})();