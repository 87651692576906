'use strict';

/* globals _ google */
(function () {
    // Store details controller
    angular.module('stores.store-details').controller('StoreDetailsController', StoreDetailsController);

    StoreDetailsController.$inject = ['storeResolve', '$mdDialog', 'Authentication', 'Stores', '$scope', '$timeout', '$interval', 'DetailsEditService', 'SchedulingService', 'TIMEZONES', 'GeocodingService'];

    function StoreDetailsController(store, $mdDialog, Authentication, Stores, $scope, $timeout, $interval, DetailsEditService, SchedulingService, TIMEZONES, GeocodingService) {
        var vm = this;
        var authentication = Authentication;
        // eslint-disable-next-line no-unused-vars
        var storeListener = $scope.$on('store-update', function (event, args) {
            if (vm.store._id === args.store._id) {
                vm.store = args.store;
                SchedulingService.getScheduleUpdateStatus(vm.store).$promise.then(function (data) {
                    if (data) {
                        vm.store.updateStatus = data;
                        vm.store.updateStatus.class = SchedulingService.scheduleUpdateStatusClasses[data.status];
                    }
                });
            }
        });

        // eslint-disable-next-line no-unused-vars
        var scheduleListener = $scope.$on('schedule-update', function (event, args) {

            if (vm.store._id === args.schedule[vm.day - 1]._id.storeId && vm.storeSchedule[vm.day - 1]._id.storeId === args.schedule[vm.day - 1]._id.storeId && vm.storeSchedule[vm.day - 1]._id.day === args.schedule[vm.day - 1]._id.day) {

                vm.storeSchedule = args.schedule;
                vm.day = vm.rightNow.isoWeekday();
                vm.getOpenStoreContactsList();

                if (vm.store.openStoreContact && vm.store.openStoreContact.description && openStoreOptionsFromScheduleOnly.indexOf(vm.store.openStoreContact.description) > -1) {
                    vm.store.openStoreContact.display = vm.contactsList[vm.store.openStoreContact.description];
                }
                Stores.updateStore(vm.store._id.toString(), vm.store);
            }
        });

        // var mapThumbnail;
        var marker; //eslint-disable-line no-unused-vars

        // All exported vm functions
        vm.showIcon = showIcon;
        vm.startEdit = startEdit;
        vm.cancelEdit = cancelEdit;
        vm.save = save;
        vm.toUpdateContact = toUpdateContact;

        vm.getOpenStoreOptionText = getOpenStoreOptionText;

        vm.addPhoneNumber = addPhoneNumber;
        vm.deletePhoneNumber = deletePhoneNumber;

        vm.editNote = editNote;
        vm.deleteNote = deleteNote;

        // vm.refreshMapThumbnail = refreshMapThumbnail;

        vm.setWidgetBodyHeight = setWidgetBodyHeight;
        vm.toggleWidgetBody = toggleWidgetBody;
        // vm.editFinancialNotes = editFinancialNotes;

        //bindable members go here
        vm.store = store;
        //vm.directory = store.directory;
        vm.directory = store.directory;
        vm.financialNotesWidgetOpen = !!vm.store.financialNotes;
        vm.notesWidgetOpen = !!vm.store.notes.length;
        vm.phoneNumbersWidgetOpen = !!vm.store.phoneNumbers.length;
        vm.status = '';

        vm.getOpenStoreContactsList = getOpenStoreContactsList;
        vm.error = null;
        vm.rightNow = moment();
        vm.day = vm.rightNow.isoWeekday();

        vm.edit = {};
        vm.contactsList = {};

        // vm.initMap = initMap;
        // if (vm.store.address) {
        //     vm.initMap2 = initMap2;
        // }

        vm.stateAbr = CallCenterConstants.US_STATES;
        vm.timezones = TIMEZONES;
        vm.openStoreOptionSelection = '';
        vm.openStoreOptions = ['Primary', 'First Backup', 'Second Backup', 'Third Backup', 'Final Contact', 'Salesman', 'Manager', 'Other'];
        var openStoreOptionsFromScheduleOnly = ['Primary', 'First Backup', 'Second Backup', 'Third Backup', 'Final Contact'];
        vm.editAuthRoles = ['store-editor', 'organizationHead', 'admin'];
        vm.basicInfoWidgetOpen = true;
        vm.basicInfoWidgetEdit = false;
        vm.notesWidgetOpen = true;
        vm.notesWidgetEdit = false;
        vm.locationsWidgetOpen = true;
        vm.locationsWidgetEdit = false;
        vm.phoneNumbersWidgetOpen = true;
        vm.phoneNumbersWidgetEdit = false;
        vm.financialNotesWidgetOpen = true;
        vm.financialNotesWidgetEdit = false;
        vm.whoToCallWidgetOpen = true;
        vm.whoToCallwidgetEdit = false;

        // vm.addingNewPhoneNumber = false;
        vm.moment = moment;

        vm.expWidgetOpen = true;
        vm.expWidgetEdit = false;

        vm.editMode = DetailsEditService.getEditMode;
        vm.setEditMode = DetailsEditService.setEditMode;
        vm.getGlobalEditMode = DetailsEditService.getGlobalEditMode;
        vm.setGlobalEditMode = DetailsEditService.setGlobalEditMode;
        vm.isSaveChangesWarningViewable = DetailsEditService.isSaveChangesWarningViewable;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            startClock();

            // vm.refreshMapThumbnail();
            //remove the listener when the scope is destroyed
            $scope.$on('$destroy', storeListener);
            $scope.$on('$destroy', scheduleListener);
            SchedulingService.getScheduleUpdateStatus(vm.store).$promise.then(function (data) {
                if (data) {
                    vm.store.updateStatus = data;
                    vm.store.updateStatus.class = SchedulingService.scheduleUpdateStatusClasses[data.status];
                }
            });

            SchedulingService.getSchedule(vm.store, vm.rightNow).then(function (response) {
                vm.storeSchedule = response;
                vm.getOpenStoreContactsList();
            }, function (err) {
                console.log(err); //eslint-disable-line no-console
            });
        }

        function startClock() {
            refreshStoreOpenStatus();
            updateCurrentTime();
            $interval(function () {
                updateCurrentTime();
            }, 1000);
            $interval(function () {
                refreshStoreOpenStatus();
            }, 60000);
        }

        function updateCurrentTime() {
            var timezone = vm.store.timezone ? vm.store.timezone : moment.tz.guess();
            var now = moment.tz(timezone);
            // had to split it like this because there was a lot of space between time and AMPM and it looked weird.
            // I wanted more control over the formatting
            vm.currentTime = {
                clock: now.format('h:mm:ss'),
                ampm: now.format('A')
            };
        }

        function refreshStoreOpenStatus() {
            SchedulingService.storeIsOpen(vm.store).then(function (isOpen) {
                if (isOpen === true) {
                    vm.status = 'OPEN';
                } else if (isOpen === false) {
                    vm.status = 'CLOSED';
                } else {
                    vm.status = '';
                }
            });
        }

        // function refreshMapThumbnail() {
        //     if (vm.locationsWidgetOpen) {
        //         $timeout(function () {
        //             initMap2();
        //         });
        //     }
        // }

        function getOpenStoreOptionText() {
            if (vm.openStoreOptionSelection !== '') {
                return vm.openStoreOptionSelection;
            } else {
                return 'Change this selection';
            }
        }

        // master 'Start Editing' function
        function startEdit(dataCategory) {
            vm.setGlobalEditMode(true);
            switch (dataCategory) {
                case 'basicInfo':
                    startEditBasicInfo();
                    break;
                case 'notes':
                    startEditNotes();
                    break;
                case 'locations':
                    startEditLocations();
                    break;
                case 'phoneNumbers':
                    startEditPhoneNumbers();
                    break;
                case 'financialNotes':
                    startEditFinancialNotes();
                    break;
                case 'whoToCall':
                    startEditWhoToCall();
                    break;
            }
        }

        function cancelEdit(category) {
            switch (category) {
                case 'basicInfo':
                    cancelEditBasicInfo();
                    break;
                case 'notes':
                    cancelEditNotes();
                    break;
                case 'locations':
                    cancelEditLocations();
                    break;
                case 'phoneNumbers':
                    cancelEditPhoneNumbers();
                    break;
                case 'financialNotes':
                    cancelEditFinancialNotes();
                    break;
                case 'whoToCall':
                    cancelEditWhoToCall();
                    break;
                default:
            }
            vm.setGlobalEditMode(false);
        }

        function save(category) {
            switch (category) {
                case 'basicInfo':
                    saveBasicInfo();
                    break;
                case 'notes':
                    saveNotes();
                    break;
                case 'locations':
                    saveLocations();
                    break;
                case 'phoneNumbers':
                    savePhoneNumbers();
                    break;
                case 'financialNotes':
                    saveFinancialNotes();
                    break;
                case 'whoToCall':
                    saveWhoToCall();
                    break;
                default:
            }
        }

        function showIcon(icon) {
            switch (icon) {
                case 'basicInfoEdit':
                    return authentication.user.canEdit && !vm.basicInfoWidgetEdit;
                case 'basicInfoMinMax':
                    return !vm.basicInfoWidgetEdit;
                case 'basicInfoCancel':
                case 'basicInfoSave':
                    return vm.basicInfoWidgetEdit;

                case 'notesEdit':
                    return authentication.user.canEdit && !vm.notesWidgetEdit;
                case 'notesMinMax':
                    return !vm.notesWidgetEdit;
                case 'notesCancel':
                case 'notesSave':
                    return vm.notesWidgetEdit;
                case 'notesAdd':
                    return authentication.user.canEdit && vm.notesWidgetEdit;

                case 'locationsEdit':
                    return authentication.user.canEdit && !vm.locationsWidgetEdit;
                case 'locationsMinMax':
                    return !vm.locationsWidgetEdit;
                case 'locationsCancel':
                case 'locationsSave':
                    return vm.locationsWidgetEdit;

                case 'phoneNumbersEdit':
                    return authentication.user.canEdit && !vm.phoneNumbersWidgetEdit;
                case 'phoneNumbersMinMax':
                    return !vm.phoneNumbersWidgetEdit;
                case 'phoneNumbersCancel':
                case 'phoneNumbersSave':
                    return vm.phoneNumbersWidgetEdit;

                case 'financialNotesEdit':
                    return authentication.user.canEdit && !vm.financialNotesWidgetEdit;
                case 'financialNotesMinMax':
                    return !vm.financialNotesWidgetEdit;
                case 'financialNotesCancel':
                case 'financialNotesSave':
                    return vm.financialNotesWidgetEdit;

                case 'whoToCallEdit':
                    return authentication.user.canEdit && !vm.whoToCallwidgetEdit;
                case 'whoToCallMinMax':
                    return !vm.whoToCallwidgetEdit;
                case 'whoToCallCancel':
                case 'whoToCallSave':
                    return vm.whoToCallwidgetEdit;

                default:
                    return false;
            }
        }

        /**
         * basic info editing
         */
        // This function is called when the Edit button is clicked and we
        // transition to editing the info
        function startEditBasicInfo() {
            vm.edit.basicInfo = {};
            _.extend(vm.edit.basicInfo, vm.store.toJSON());
            vm.edit.directory = vm.store.directory;
            vm.basicInfoWidgetEdit = vm.basicInfoWidgetOpen = true;
        }
        // these save functions can be merged together into a generic save
        // This function is called to save the edited basic info
        function saveBasicInfo() {
            function saveSuccess() {
                //vm.store = store;
                endEditBasicInfo();
            }
            function saveError(err) {
                alert(err);
            }

            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.basicInfo;

            _.extend(updatedStore, newInfo);

            if (updatedStore.defaultTimes) {
                var times = {
                    'saturday-open': updatedStore.defaultTimes.saturday ? updatedStore.defaultTimes.saturday.openTime : null,
                    'saturday-close': updatedStore.defaultTimes.saturday ? updatedStore.defaultTimes.saturday.closeTime : null,

                    'sunday-open': updatedStore.defaultTimes.sunday ? updatedStore.defaultTimes.sunday.openTime : null,
                    'sunday-close': updatedStore.defaultTimes.sunday ? updatedStore.defaultTimes.sunday.closeTime : null,

                    'weekday-open': updatedStore.defaultTimes.weekday ? updatedStore.defaultTimes.weekday.openTime : null,
                    'weekday-close': updatedStore.defaultTimes.weekday ? updatedStore.defaultTimes.weekday.closeTime : null
                };

                // validate default times
                for (var key in times) {
                    var val = times[key] ? times[key].toLowerCase().trim() : null;

                    // normalize time string values for validation
                    var normalizedValue = val;
                    if (val && val !== 'closed') {
                        // remove colon, am/pm and whitespace
                        normalizedValue = normalizedValue.replace(/:/g, '').replace(/(a|p)m/g, '').trim();

                        // add leading zero, if needed (800 -> 0800)
                        while (normalizedValue.length < 4) {
                            normalizedValue = '0' + normalizedValue;
                        }
                    }
                    if (normalizedValue && normalizedValue !== 'closed' && normalizedValue.length !== 4) {
                        return saveError('Usual store time "' + val + '" for "' + key + '" is invalid. It should be a time like H:MM, "closed" or empty.');
                    }
                }
            }
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        // This function is called when we finish editing and want to
        // transition back to not editing
        function endEditBasicInfo() {
            vm.basicInfoWidgetEdit = false;
            delete vm.edit.directory;
            delete vm.edit.basicInfo;
            vm.setGlobalEditMode(false);
        }
        // This function is called when the user clicks the cancel button
        function cancelEditBasicInfo() {
            endEditBasicInfo();
        }
        function toUpdateContact(dir) {
            return {
                name: dir.name,
                email: dir.email,
                phone: dir.phone
            };
        }

        /**
         * Notes editing
         */
        function startEditNotes() {
            vm.edit.notes = [];
            _.forEach(vm.store.notes, function (n) {
                vm.edit.notes.push(n);
            });
            vm.notesWidgetEdit = vm.notesWidgetOpen = true;
        }
        function saveNotes() {
            function saveSuccess() {
                //vm.store = store;
                endEditNotes();
            }
            function saveError(err) {
                console.log(err); //eslint-disable-line no-console
            }

            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.notes;

            updatedStore.notes = newInfo;
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function endEditNotes() {
            vm.notesWidgetEdit = false;
            delete vm.edit.notes;
            vm.setGlobalEditMode(false);
        }
        function cancelEditNotes() {
            endEditNotes();
        }

        /**
         * Locations editing
         */
        function startEditLocations() {
            vm.edit.location = {};
            _.extend(vm.edit.location, vm.store.address);
            vm.locationsWidgetEdit = vm.locationsWidgetOpen = true;
        }
        function saveLocations() {
            function saveSuccess() {
                //vm.store = store;
                endEditLocations();
            }
            function saveError(err) {
                console.log(err); //eslint-disable-line no-console
            }

            if (!vm.store.address) {
                vm.store.address = {};
            }
            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.location;

            _.extend(updatedStore.address, newInfo);
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function endEditLocations() {
            vm.locationsWidgetEdit = false;
            delete vm.edit.location;
            vm.setGlobalEditMode(false);
            // vm.refreshMapThumbnail();
        }
        function cancelEditLocations() {
            endEditLocations();
        }

        /**
         * Phone Number editing
         */
        function startEditPhoneNumbers() {
            vm.edit.phoneNumbers = [];
            _.forEach(vm.store.phoneNumbers, function (pn) {
                vm.edit.phoneNumbers.push(_.extend({}, pn));
            });
            vm.phoneNumbersWidgetEdit = vm.phoneNumbersWidgetOpen = true;
        }
        function savePhoneNumbers() {
            function saveSuccess() {
                //vm.store = store;
                endEditPhoneNumbers();
            }
            function saveError(err) {
                console.log(err); //eslint-disable-line no-console
            }

            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.phoneNumbers;

            updatedStore.phoneNumbers = newInfo;
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function endEditPhoneNumbers() {
            vm.phoneNumbersWidgetEdit = false;
            vm.addingNewPhoneNumber = false;
            delete vm.edit.phoneNumbers;
            vm.setGlobalEditMode(false);
        }
        function cancelEditPhoneNumbers() {
            endEditPhoneNumbers();
        }

        function addPhoneNumber() {
            vm.addingNewPhoneNumber = true;
            vm.edit.phoneNumbers = vm.edit.phoneNumbers || [];
            vm.edit.phoneNumbers.push({
                label: '',
                number: '',
                note: '',
                isNew: true
            });
        }
        function deletePhoneNumber(idx) {
            vm.edit.phoneNumbers.splice(idx, 1);
        }

        /**
         * FinancialNotes editing
         */
        function startEditFinancialNotes() {
            vm.edit.financialNotes = '';
            vm.edit.financialNotes = vm.store.financialNotes;
            vm.financialNotesWidgetEdit = vm.financialNotesWidgetOpen = true;
        }
        function saveFinancialNotes() {
            function saveSuccess() {
                //vm.store = store;
                endEditFinancialNotes();
            }
            function saveError(err) {
                alert(err);
            }

            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.financialNotes;

            updatedStore.financialNotes = newInfo;
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function endEditFinancialNotes() {
            vm.financialNotesWidgetEdit = false;
            delete vm.edit.financialNotes;
            vm.setGlobalEditMode(false);
        }
        function cancelEditFinancialNotes() {
            endEditFinancialNotes();
        }

        /**
         * Who To Call editing
         */
        function startEditWhoToCall() {
            vm.edit.openStoreContact = {
                description: '',
                display: ''
            };
            vm.edit.openStoreContact = vm.store.openStoreContact;
            vm.whoToCallwidgetEdit = vm.whoToCallWidgetOpen = true;
        }
        function saveWhoToCall() {
            function saveSuccess() {
                //vm.store = store;
                endEditWhoToCall();
            }
            function saveError(err) {
                alert(err);
            }

            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.openStoreContact;

            updatedStore.openStoreContact = newInfo;
            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function endEditWhoToCall() {
            vm.whoToCallwidgetEdit = false;
            delete vm.edit.openStoreContact;
            vm.setGlobalEditMode(false);
        }
        function cancelEditWhoToCall() {
            endEditWhoToCall();
        }

        // This function gets called when you edit, create, or delete a note
        // from the Notes and Warnings widget
        function editNote(ev, newNote, idx) {
            if (!vm.notesWidgetEdit || !authentication.user.canEdit) {
                //not authorized to edit notes, or editing not "on"
                return null;
            }

            var note = {};

            if (!newNote) {
                note = vm.edit.notes[idx];
            } else {
                note = '';
            }

            $mdDialog.show({
                locals: {
                    newNote: newNote,
                    note: note
                },
                bindToController: true,
                controller: 'NotesController',
                controllerAs: 'notes',
                templateUrl: 'modules/stores/features/store-details/notes/store-details.notes-modal.client.view.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose: false,
                fullscreen: true
            }).then(function (note) {
                var dl = note;

                if (dl == null) {
                    // eslint-disable-line eqeqeq
                    //delete note
                    if (idx != null) {
                        vm.edit.notes.splice(idx, 1);
                    }
                } else {
                    dl = dl.replace(/<p>/g, '<div>');
                    dl = dl.replace(/<\/p>/g, '</div>');
                    if (idx !== undefined) {
                        vm.edit.notes[idx] = dl;
                    } else {
                        vm.edit.notes.push(dl);
                    }
                }
            }, function () {
                // Gasper, do whatever you need to do if you click cancel, here
            });
        }
        function deleteNote(index) {
            if (!vm.notesWidgetEdit) {
                return;
            }
            vm.edit.notes.splice(index, 1);
        }

        // function initMap2() {
        //     if (!vm.store.address) {
        //         return;
        //     }

        //     var address = `${vm.store.address.street1} ${vm.store.address.city}, ${vm.store.address.state} ${vm.store.address.zip}`;
        //     //===============
        //     mapboxgl.accessToken = 'pk.eyJ1IjoiZGNpLWRldmVsb3BtZW50IiwiYSI6ImNraTU0YzV3ZDI0ZHcyeHA1OTVmNHozOG4ifQ.IO1sJmnSW85UOxDMQ3NfBw'
        //     const mapThumbnail = new mapboxgl.Map({
        //         container: 'mapThumbnail',
        //         style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
        //         center: [-89.8, 31.2], // starting position [lng, lat]
        //         zoom: 9 // starting zoom
        //     });
        //     var geocoder2 = '';
        //     geocodeAddress2(geocoder2, mapThumbnail, address);
        // }

        // function geocodeAddress2(geocoder2, resultsMap2, address) {
        //     var centerPoint;
        //     GeocodingService.getCoordinates(address).$promise.then(function(response){centerPoint = response.center
        //         console.log('Centerpoint from service in storedetailsclientcont')
        //         console.log(centerPoint)
        //         const storeIcon = document.createElement('div');
        //         storeIcon.className = 'store-marker';
        //         var marker = new mapboxgl.Marker(storeIcon)
        //         .setLngLat(centerPoint)
        //         .addTo(resultsMap2);
        //         resultsMap2.jumpTo({center: centerPoint})
        //     })

        // }

        // function initMap() {
        //     var largeMap = window.open('modules/stores/features/store-details/store-details.client.view.map.html');
        // }

        function setWidgetBodyHeight(wId) {
            var w = document.getElementById(wId);
            var h = 0;
            var count = w.childElementCount;
            var children = w.children;

            for (var i = 0; i < count; i++) {
                h += children[i].clientHeight;
            }

            w.clientHeight = h;

            return h;
        }

        function toggleWidgetBody(wId) {
            var w = document.getElementById(wId);
            vm.expWidgetOpen = !vm.expWidgetOpen;

            if (vm.expWidgetOpen) {
                w.clientHeight = setWidgetBodyHeight(wId);
            } else {
                w.clientHeight = 0;
            }
        }

        function getOpenStoreContactsList() {
            var managerString = '';
            var salesString = '';
            var scheduleContacts = vm.storeSchedule[vm.day - 1];

            vm.contactsList = {
                'Primary': scheduleContacts.assignments ? scheduleContacts.assignments.primary ? scheduleContacts.assignments.primary.message ? scheduleContacts.assignments.primary.message : scheduleContacts.assignments.primary.name : '' : '',
                'First Backup': scheduleContacts.assignments ? scheduleContacts.assignments.secondary ? scheduleContacts.assignments.secondary.message ? scheduleContacts.assignments.secondary.message : scheduleContacts.assignments.secondary.name : '' : '',
                'Second Backup': scheduleContacts.assignments ? scheduleContacts.assignments.tertiary ? scheduleContacts.assignments.tertiary.message ? scheduleContacts.assignments.tertiary.message : scheduleContacts.assignments.tertiary.name : '' : '',
                'Third Backup': scheduleContacts.assignments ? scheduleContacts.assignments.quaternary ? scheduleContacts.assignments.quaternary.message ? scheduleContacts.assignments.quaternary.message : scheduleContacts.assignments.quaternary.name : '' : '',
                'Final Contact': scheduleContacts.assignments ? scheduleContacts.assignments.final ? scheduleContacts.assignments.final.message ? scheduleContacts.assignments.final.message : scheduleContacts.assignments.final.name : '' : ''
            };

            for (var i = 0; i < vm.directory.length; i++) {
                if (vm.directory[i].type === 'sales') {
                    salesString += vm.directory[i].name + (i === vm.directory.length - 1 ? '' : ', ');
                }
                if (vm.directory[i].type === 'manager') {
                    managerString += vm.directory[i].name + (i === vm.directory.length - 1 ? '' : ', ');
                }
            }

            vm.contactsList.Salesman = salesString;
            vm.contactsList.Manager = managerString;
            vm.contactsList.Other = vm.store.openStoreContact ? vm.store.openStoreContact.description === 'Other' ? vm.store.openStoreContact.display : '' : '';
        }
    }
})();