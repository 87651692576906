'use strict';

(function () {

    angular.module('core').filter('capitalize', CapitalizeFilter);

    CapitalizeFilter.$inject = [];

    function CapitalizeFilter() {
        return function (input) {
            if (!input || !input.length) {
                return input;
            }
            if (input.length === 1) {
                return input.toUpperCase();
            }
            return input[0].toUpperCase() + input.substring(1).toLowerCase();
        };
    }
})();