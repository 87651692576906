'use strict';

(function () {
    angular.module('core').filter('format', formatFilter);

    var formatters = {
        phone: phoneFormatter
    };

    formatFilter.$inject = [];
    function formatFilter() {
        return function (input, format, formatSpec) {
            if (input && formatters[format]) {
                return formatters[format](input, formatSpec);
            }
            return input;
        };
    }

    function phoneFormatter(input) {
        if (!isStr(input)) {
            input = input.toString();
        }
        input = input.replace(/[- ()]/g, '');
        var iLen = input.length;
        switch (iLen) {
            case 7:
                {
                    var pre = input.slice(0, 3),
                        post = input.slice(3, 6);
                    return pre + '-' + post;
                }
            case 10:
                {
                    var ac = input.slice(0, 3),
                        _pre = input.slice(3, 6),
                        _post = input.slice(6, 10);
                    return '(' + ac + ') ' + _pre + '-' + _post;
                }
            case 11:
                {
                    //this only works for US numbers!!!
                    var cc = input.slice(0, 1),
                        _ac = input.slice(1, 4),
                        _pre2 = input.slice(4, 7),
                        _post2 = input.slice(7, 11);
                    return '+' + cc + ' (' + _ac + ') ' + _pre2 + '-' + _post2;
                }
            default:
                return input;
        }
    }

    function isStr(obj) {
        return Object.prototype.toString.call(obj) === '[object String]';
    }
})();