'use strict';

(function () {
    // Store details controller
    angular.module('stores.store-search').controller('StoreSearchController', StoreSearchController);

    StoreSearchController.$inject = ['SocketService', 'NaughtyListService', '$state', '$filter', '$mdConstant', 'Organizations', 'uiGridConstants', 'SchedulingService', 'Authentication', 'Stores', 'NaughtyListService'];

    function StoreSearchController(SocketService, naughtyListService, $state, $filter, $mdConstant, organizations, uiGridConstants, SchedulingService, Authentication, StoreService, NaughtyListService) {
        var vm = this;

        //bindable members go here
        vm.stores = [];
        vm.searchData = [];
        vm.authentication = Authentication;
        vm.error = null;
        vm.searchQuery = '';
        vm.searchCriteria = [];
        vm.isOpen = false;
        vm.selectedMode = 'md-scale';
        vm.selectedDirection = 'right';
        vm.naughtyList = [];
        vm.showNaughtyList = false;
        vm.showInactiveStores = false;
        vm.organizationLogos = {};
        vm.showInactiveStoresChanged = showInactiveStoresChanged;
        vm.toggleNaughtyList = toggleNaughtyList;
        vm.statusUpdateRequests = [];

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            getSelectOrganizations();
            Promise.all([getStores(), getNaughtyList()]).then(function () {
                populateScheduleStatuses();
                setGridData(vm.stores);
            });
        }

        function getStores() {
            return StoreService.resource.query().$promise.then(function (stores) {
                vm.stores = stores;
                getOrgLogos();
                return;
            });
        }

        function setGridData(storeList) {
            vm.searchData = [];
            storeList.forEach(function (store) {
                // add contactphone prop, I can't get ui grid to show nested data
                store.updateContactPhone = store.updateContact ? store.updateContact.phone : '';

                // normalize data for quicker searching
                var normalizedAddress = store.address ? (store.address.street1 || '') + ' ' + (store.address.street2 || '') + ' ' + (store.address.city || '') + ' ' + (store.address.state || '') + ' ' + (store.address.zip || '') + ' ' + (store.address.notes || '') : '';
                var normalizedDirectory = (store.directory || []).map(function (dir) {
                    return (dir.name || '') + ' ' + (dir.email || '') + ' ' + (dir.maddenCoId || '') + ' ' + (dir.notes || '');
                });
                var normalizedPhoneNumbers = (store.phoneNumbers || []).map(function (ph) {
                    return (ph.number || '') + ' ' + (ph.note || '');
                });
                var normalizedContact = store.updateContact ? (store.updateContact.name || '') + ' ' + (store.updateContact.phone || '') : '';
                vm.searchData.push({
                    _id: store._id,
                    name: store.name ? store.name.toLowerCase() : '',
                    storeNumber: store.storeNumber ? store.storeNumber.toString().toLowerCase() : '',
                    notes: store.notes ? store.notes.join(' ').toLowerCase() : '',
                    storeNotes: store.storeNotes ? store.storeNotes.toLowerCase() : '',
                    financialNotes: store.financialNotes ? store.financialNotes.toLowerCase() : '',
                    dispatchPrinterNumber: store.dispatchPrinterNumber ? store.dispatchPrinterNumber.toLowerCase() : '',
                    billingPrinterNumber: store.billingPrinterNumber ? store.billingPrinterNumber.toLowerCase() : '',
                    updateContact: normalizedContact.toLowerCase(),
                    address: normalizedAddress.toLowerCase(),
                    openStoreContact: store.openStoreContact && store.openStoreContact.display ? store.openStoreContact.display.toLowerCase() : '',
                    directory: normalizedDirectory.join(' ').toLowerCase(),
                    organization: store.organization ? store.organization.name : '',
                    phoneNumbers: normalizedPhoneNumbers.join(' ').toLowerCase(),
                    timezone: store.timezone ? store.timezone.toLowerCase() : ''
                });
            });
            vm.gridOptions.data = storeList;
        }

        function getOrgLogos() {
            var logoPromises = [];
            var visitedOrganizations = [];

            var _loop = function _loop() {
                var id = vm.stores[i].organization && vm.stores[i].organization._id ? vm.stores[i].organization._id.toString() : null;
                if (visitedOrganizations.indexOf(id) === -1) {
                    visitedOrganizations.push(id);
                    logoPromises.push(organizations.getLogo(id).then(function (logo) {
                        vm.organizationLogos[id] = logo;
                    }));
                }
            };

            for (var i = 0; i < vm.stores.length; i++) {
                _loop();
            }

            Promise.all(logoPromises).then(function () {
                for (var i = 0; i < vm.stores.length; i++) {
                    var _id = vm.stores[i].organization && vm.stores[i].organization._id ? vm.stores[i].organization._id.toString() : null;
                    if (_id) {
                        vm.stores[i].organization.logoURL = vm.organizationLogos[_id];
                    }
                }
            });
        }

        function getNaughtyList() {
            return naughtyListService.getNaughtyList().$promise.then(function (naughtyList) {
                vm.naughtyList = naughtyList;
                return;
            });
        }

        function populateScheduleStatuses() {
            var rightNow = moment().startOf('day').format('YYYY-MM-DD');
            vm.statusUpdateRequests = [];
            vm.stores.forEach(function (store) {
                populateScheduleUpdateStatus(store, rightNow);
            });
            vm.naughtyList.forEach(function (naughtyStore) {
                populateScheduleUpdateStatus(naughtyStore, rightNow);
            });
        }

        var type = {
            name: 'Organization', field: 'organization', maxWidth: 140, cellTemplate: '<div class="ui-grid-cell-contents"><div class="org-img-container"><img class="small-org-logo" ng-src="{{grid.getCellValue(row, col).logoURL}}" alt="Description"/></div></div>',
            filter: {
                type: uiGridConstants.filter.SELECT,
                selectOptions: {},
                condition: function condition(searchTerm, cellValue) {
                    return cellValue._id.toString() === searchTerm;
                }

            }
        };

        vm.gridOptions = {
            appScopeProvider: vm,
            columnDefs: [type, { name: 'Store Name', maxWidth: 300, field: 'name', cellTooltip: true, cellTemplate: '<div class="ui-grid-cell-contents{{row.entity.status === \'inactive\' ? \' inactive-store-name\' : \'\'}}">{{grid.getCellValue(row, col)}}<span ng-if="row.entity.status === \'inactive\'" class="inactive-txt">&nbsp;&nbsp;INACTIVE</span></div>' }, { name: 'Phone', maxWidth: 115, field: 'updateContactPhone', cellTemplate: '<div class="ui-grid-cell-contents" title="{{grid.getCellValue(row, col) | format: \'phone\'}}"><a href="tel:{{grid.getCellValue(row,col)}}">{{grid.getCellValue(row, col) | format: \'phone\'}}</a></div>' }, { name: 'Dispatch Printer', maxWidth: 175, cellTooltip: true, field: 'dispatchPrinterNumber' }, { name: 'Billing Printer', maxWidth: 175, cellTooltip: true, field: 'billingPrinterNumber' }, { name: 'Financial Notes', field: 'financialNotes', cellTooltip: true }, { name: 'Update Status', maxWidth: 220, field: 'updateStatus', cellTooltip: true, enableFiltering: false, cellTemplate: '<div class="ui-grid-cell-contents {{grid.getCellValue(row,col).class}}" title="{{grid.getCellValue(row, col).fullString}}">{{grid.getCellValue(row, col).fullString}}</div>' }],
            data: [],
            rowHeight: 40,
            enableHorizontalScrollbar: 0,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            enableFiltering: true,
            multiSelect: false,
            enableSorting: true,
            flatEntityAccess: true,
            onRegisterApi: function onRegisterApi(gridApi) {
                vm.gridApi = gridApi;
                vm.gridApi.selection.on.rowSelectionChanged(null, function (row, event) {
                    vm.statusUpdateRequests.forEach(function (request) {
                        request.$cancelRequest();
                    });
                    if (!event || !event.target || !event.target.href || event.target.href.indexOf('tel:') === -1) {
                        var selection = vm.gridApi.selection.getSelectedRows();
                        $state.go('store-details', { storeId: selection[0]._id });
                    }
                });
            }
        };

        function populateScheduleUpdateStatus(store, rightNow) {
            var statusUpdateRequest = SchedulingService.getScheduleUpdateStatus(store, rightNow);
            vm.statusUpdateRequests.push(statusUpdateRequest);
            statusUpdateRequest.$promise.then(function (data) {
                if (data) {
                    store.updateStatus = data;
                    store.updateStatus.class = SchedulingService.scheduleUpdateStatusClasses[data.status];
                }
            });
        }

        function getSelectOrganizations() {
            var organizationsArray = organizations.query();
            var finalArray = [];
            organizationsArray.$promise.then(function (result) {
                for (var i = 0; i < result.length; i++) {
                    finalArray.push({ value: result[i]._id, label: result[i].name });
                }
                type.filter.selectOptions = finalArray;

                return;
            });
        }

        // MD chip custom keys
        var spaceBar = 32;
        vm.customKeys = [$mdConstant.KEY_CODE.ENTER, spaceBar];

        vm.searchFilter = function () {
            //When no criteria show all stores.
            if (vm.searchCriteria.length < 1) {
                setGridData(vm.stores);
            }

            //Loop through each search criteria
            angular.forEach(vm.searchCriteria, function (criteria) {
                var matchedStores = $filter('filter')(vm.searchData, criteria, function (actual, expected) {
                    return expected !== '' && (actual || '').toString().toLowerCase().indexOf(expected.toString().toLowerCase()) > -1;
                });
                var matchedIds = matchedStores.map(function (s) {
                    return s._id.toString();
                });
                setGridData(vm.stores.filter(function (st) {
                    return matchedIds.indexOf(st._id.toString()) > -1;
                }));
            });
        };

        function toggleNaughtyList() {
            if (vm.showNaughtyList) {
                setGridData(vm.naughtyList);
            } else {
                setGridData(vm.stores);
            }
        }

        function showInactiveStoresChanged() {
            var promises = [StoreService.resource.query({ includeInactive: vm.showInactiveStores }).$promise, NaughtyListService.getNaughtyList(vm.showInactiveStores)];
            Promise.all(promises).then(function (results) {
                vm.stores = results[0];
                vm.naughtyList = results[1];
                getOrgLogos();
                populateScheduleStatuses();
                vm.searchFilter();
            });
        }
    }
})();