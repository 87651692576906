'use strict';

(function () {
    // Stores controller
    angular.module('stores.crud').controller('StoresCRUDController', StoresCRUDController);

    StoresCRUDController.$inject = ['$rootScope', '$scope', '$state', 'storeResolve', 'Organizations'];

    function StoresCRUDController($rootScope, $scope, $state, store, organizations) {
        /*jshint validthis: true */
        var vm = this;

        //bindable members go here
        vm.store = store;
        vm.error = null;
        vm.storeForm = {};
        vm.selectedOrganization = null;
        vm.remove = remove;
        vm.save = save;
        vm.state = $state;
        vm.getPreviousState = getPreviousState;
        vm.getPreviousStateParams = getPreviousStateParams;
        vm.getSelectedOrganization = getSelectedOrganization;
        vm.organizations = organizations.query();
        vm.states = CallCenterConstants.STATES;

        //initialize the controller
        activate();
        vm.checkStats = function () {
            vm.storeChecked = true;
        };
        //function declarations go here
        function activate() {}

        // Remove existing Store
        function remove() {
            if (confirm('Are you sure you want to delete?')) {
                vm.store.$remove($state.go('stores.list'));
            }
        }

        // Save Store
        function save(isValid) {
            if (!isValid) {
                $scope.$broadcast('show-errors-check-validity', 'vm.form.storeForm');
                return false;
            }
            if (vm.store._id) {
                vm.store.$update(successCallback, errorCallback);
            } else {
                vm.store.$save(successCallback, errorCallback);
            }

            function successCallback(res) {
                $state.go('store-details', {
                    storeId: res._id
                });
            }

            function errorCallback(res) {
                vm.error = res.data.message;
            }
        }

        function getPreviousState() {
            // console.log('Previous state name: ' + $rootScope.previousState.name);
            // console.log($rootScope.previousState);
            return $rootScope.previousState.name;
        };

        function getPreviousStateParams() {
            // console.log($rootScope.previousStateParams);
            return $rootScope.previousStateParams;
        };

        function getSelectedOrganization() {
            vm.selectedOrganization = null;
            for (var i = 0; i < vm.organizations.length; i++) {
                if (vm.organizations[i]._id.toString() === vm.store.organization._id.toString()) {
                    vm.selectedOrganization = vm.organizations[i];
                    break;
                }
            }
        }
    }
})();