'use strict';

/* globals _ */
(function () {

    angular.module('core').filter('lodash', lodashFilter);

    lodashFilter.$inject = [];

    function lodashFilter() {
        return function (input, fn) {
            if (!_) {
                throw new Error('lodashFilter Error: lodash is not loaded!');
            }
            if (!_[fn]) {
                throw new Error('lodashFilter Error: function ' + fn + ' does not exist!');
            }
            try {
                return _[fn](input);
            } catch (err) {
                console.error(err); //eslint-disable-line no-console
                return input;
            }
        };
    }
})();