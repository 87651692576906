'use strict';

(function () {
    // Setting up route
    angular.module('users.settings').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        // Users settings state routing
        $stateProvider.state('password', {
            url: '/settings/password',
            templateUrl: 'modules/users/features/users-settings/views/change-password.client.view.html',
            controller: 'UsersSettingsController',
            controllerAs: 'vm',
            params: {
                inGlobalEdit: false
            }
        });
    }
})();