'use strict';

(function () {
    angular.module('admin.dashboard').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('admin', {
            abstract: true,
            url: '/admin',
            template: '<ui-view/>',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('admin.dashboard', {
            url: '/dashboard',
            resolve: {
                users: ['Users', '$stateParams', function (Users) {
                    return Users.list({}).$promise;
                }],
                organizationListResolve: getOrganizationList
                // storeListResolve: getStoreList
            },
            params: {
                inGlobalEdit: false
            },
            views: {
                '': {
                    templateUrl: 'modules/admin/features/admin-dashboard/views/admin-dashboard.client.view.html',
                    controller: 'AdminDashboardController as adminDash'
                },
                'organizations@admin.dashboard': {
                    templateUrl: 'modules/admin/features/admin-dashboard/views/organizations-admin-dashboard.client.view.html',
                    controller: 'OrganizationsListController as organizations'
                },
                'stores@admin.dashboard': {
                    templateUrl: 'modules/admin/features/admin-dashboard/views/stores-admin-dashboard.client.view.html',
                    controller: 'StoresListController as stores'
                },
                'users@admin.dashboard': {
                    templateUrl: 'modules/admin/features/admin-dashboard/views/users-admin-dashboard.client.view.html',
                    controller: 'UsersListController as users'
                }
            }

        });
    }

    getOrganizationList.$inject = ['Organizations'];
    function getOrganizationList(Organizations) {
        return Organizations.query().$promise;
    }

    getStoreList.$inject = ['Stores'];
    function getStoreList(Stores) {
        return Stores.resource.query().$promise;
    }
})();