'use strict';

(function () {
  // Users service used for communicating with the users REST endpoint
  angular.module('stores').factory('GeocodingService', geocodingService);

  geocodingService.$inject = ['$resource'];

  function geocodingService($resource) {
    var coordinatesResource = $resource('stores/locate', {}, {
      get: {
        method: 'GET',
        isArray: false
      }
    });

    var geocodeSvc = {
      getCoordinates: getCoordinates
    };
    return geocodeSvc;

    function getCoordinates(address) {
      return coordinatesResource.get({ address: address });
    };
  };
})();