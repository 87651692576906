'use strict';

(function () {
    // Setting up route
    angular.module('dev.authentication').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        // Users authentication state routing
        $stateProvider.state('dev', {
            abstract: true,
            template: '<ui-view/>',
            url: '/dev'
        }).state('dev.signup', {
            url: '/signup',
            templateUrl: 'modules/dev/features/dev-authentication/views/dev-signup.client.view.html',
            controller: 'DevAuthenticationController',
            controllerAs: 'vm',
            data: {
                requiresLogin: false
            }
        }).state('dev.signin', {
            url: '/signin',
            templateUrl: 'modules/dev/features/dev-authentication/views/dev-signin.client.view.html',
            controller: 'DevAuthenticationController',
            controllerAs: 'vm',
            data: {
                requiresLogin: false
            }
        });
    }
})();