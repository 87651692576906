'use strict';

(function () {
    // Stores List controller
    angular.module('stores.crud').controller('StoresListController', StoresListController);

    StoresListController.$inject = ['Authentication', '$state', 'Stores'];

    function StoresListController(Authentication, $state, Stores) {

        /*jshint validthis: true */
        var vm = this;
        vm.state = $state;

        //bindable members go here
        vm.stores = [];
        vm.error = null;
        vm.authentication = Authentication;
        vm.status = 'active';

        vm.updateStoreStatus = function (store) {
            Stores.resource.update(store).$promise.then(function (response) {
                console.log(response);
            });
        };

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            Stores.resource.query({ select: 'name status created organization', includeLogo: true, includeInactive: true }).$promise.then(function (stores) {
                vm.stores = stores;
            });
        }
    }
})();