'use strict';

// This is a filter that converts \n to <br> for displaying the
// Financial Notes string as htnml.  This is done so the note
// will keep whatever spacing/newline/etc that the user put in
// the note as they were typing it
angular.module('core').filter('nl2br', nl2br);

function nl2br() {
    return function (text) {
        return text ? text.replace(/\n/g, '<br>') : '';
    };
}