'use strict';

(function () {
    angular.module('customers.roadside-service').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('roadside-service', {
            url: '/roadside-service',
            templateUrl: 'modules/customers/features/roadside-service/roadside-service.client.view.html',
            controller: 'RoadsideServiceController',
            controllerAs: 'vm',
            data: {
                pageTitle: 'Roadside Service',
                roles: ['admin', 'developer']
            }
        });
    }
})();