'use strict';

(function () {

    angular.module('core').filter('prettySnake', PrettySnake);

    PrettySnake.$inject = [];

    function PrettySnake() {
        return function (input) {
            return _.startCase(_.toLower(input));
        };
    }
})();