'use strict';

//Organizations service used to communicate Organizations REST endpoints
(function () {
    angular.module('core').factory('ErrorService', ErrorService);

    ErrorService.$inject = ['$http'];

    function ErrorService($http) {
        function emailClientError(err) {
            $http.post('/client-error', err).success(function (response) {
                console.log('client error sent to server');
                console.log(response);
            }).error(function (response) {
                console.error('error sending client error to server');
                console.error(response);
            });
        }

        return {
            emailClientError: emailClientError
        };
    }
})();