'use strict';

(function () {
    // Sets keeps track of the edit mode for the entire store details page
    angular.module('core').factory('DetailsEditService', DetailsEditService);

    DetailsEditService.$inject = ['$timeout', '$state'];

    function DetailsEditService($timeout, $state) {
        var globalEditMode = false;
        var saveChangesModalViewable = false;

        var svc = {
            setGlobalEditMode: setGlobalEditMode,
            getGlobalEditMode: getGlobalEditMode,
            navigateIfAllowed: navigateIfAllowed,
            toggleSaveChangesWarning: toggleSaveChangesWarning,
            isSaveChangesWarningViewable: isSaveChangesWarningViewable
        };

        return svc;

        function toggleSaveChangesWarning() {
            saveChangesModalViewable = true;
            $timeout(function () {
                saveChangesModalViewable = false;
            }, 2000);
        }

        function isSaveChangesWarningViewable() {
            return saveChangesModalViewable;
        }

        function setGlobalEditMode(editModeValue) {
            globalEditMode = editModeValue;
            return globalEditMode;
        }

        function getGlobalEditMode() {
            return globalEditMode;
        }

        function navigateIfAllowed(value, type) {
            if (!type) {
                $state.go(value, { inGlobalEdit: globalEditMode });
            } else if (type === 'url') {
                if (globalEditMode) {
                    toggleSaveChangesWarning();
                } else {
                    window.location.href = value;
                }
            }
        }
    }

    // Sets keeps track of the edit mode for the entire store details page
    angular.module('core').factory('NavigationNotAllowedCtrl', NavigationNotAllowedCtrl);

    NavigationNotAllowedCtrl.$inject = [];

    function NavigationNotAllowedCtrl() {}
})();