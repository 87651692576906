'use strict';

(function () {
    angular.module('help').controller('HelpController', HelpController);

    //dependency injections for the controller go here
    HelpController.$inject = ['$state', 'Authentication'];

    function HelpController($state, Authentication) {

        /*jshint validthis: true */
        var vm = this;
        vm.authentication = Authentication;
        vm.admin = false;

        if (vm.authentication.user.roles.indexOf('admin') > -1 || vm.authentication.user.roles.indexOf('developer') > -1 || vm.authentication.user.roles.indexOf('organizationHead') > -1) {
            vm.admin = true;
        }
    }
})();