'use strict';

(function () {
    // Organizations controller
    angular.module('customers.roadside-service-request').controller('RoadsideServiceRequestController', RoadsideServiceRequestController);

    RoadsideServiceRequestController.$inject = ['$scope', 'Customers', 'Authentication', '$state', '$window', '$location', '$anchorScroll'];

    function RoadsideServiceRequestController($scope, customerService, Authentication, $state, $window, $location, $anchorScroll) {
        //////////////////////////////////////////////////////////
        // Redirect to new connect site on page load
        //////////////////////////////////////////////////////////
        $window.location.href = "http://connect.stmtires.com";

        /*jshint validthis: true */
        var vm = this;
        vm.loggedIn = false;
        vm.requestForm = null;
        vm.requestData = {
            problems: [{}]
        };
        vm.submitAttempted = false;
        vm.submitErr = false;
        vm.dispatchPhone = '(877) 786-8473';
        vm.states = CallCenterConstants.US_STATES;
        vm.logo = $location.host().indexOf('stmpilot.com') > -1 ? 'modules/core/img/brand/STMP_logo.svg' : 'modules/core/img/brand/STM_logo.png';

        vm.submitForm = submitForm;
        vm.selectProblemArea = selectProblemArea;
        vm.selectTireSize = selectTireSize;
        vm.addProblem = addProblem;
        vm.removeProblem = removeProblem;
        vm.selectTirePosition = selectTirePosition;
        vm.tireSizes = CallCenterConstants.COMMON_TIRE_SIZES;
        vm.truckTirePositions = CallCenterConstants.WHEEL_POSITIONS;
        vm.trailerTirePositions = _.filter(angular.copy(vm.truckTirePositions), function (tp) {
            return tp.type !== 'steer';
        });

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            vm.loggedIn = Authentication.user;
        }

        function addProblem() {
            var newProblem = {};
            // newProblem.tireSize = vm.requestData.problems[0].tireSize;
            // newProblem.tireSizeOption = vm.requestData.problems[0].tireSizeOption;
            vm.requestData.problems.push(newProblem);
            var newHash = 'problem' + (vm.requestData.problems.length - 1);

            if ($location.hash() !== newHash) {
                $location.hash(newHash);
            } else {
                $anchorScroll();
            }
        }

        function removeProblem(idx) {
            vm.requestData.problems.splice(idx, 1);
        }

        function submitForm() {
            vm.submitAttempted = true;
            vm.requestForm.$pristine = false;
            vm.submitErr = false;
            $location.hash('submitBtn');
            if (vm.requestForm.$valid) {
                var host = $location.host();
                switch (host) {
                    case 'oncall.stmpilot.com':
                    case 'oncall.dev.stmpilot.com':
                        vm.requestData.source = { name: 'webFormPilot' };
                        break;
                    default:
                        vm.requestData.source = { name: 'webForm' };
                        break;
                }
                vm.requestData.truckNumber = vm.requestData.truckNumber || '';
                vm.requestData.trailerNumber = vm.requestData.trailerNumber || '';
                vm.requestData.notes = vm.requestData.notes || '';
                vm.requestData.problems.forEach(function (p) {
                    p.wheelRequestDetails = p.wheelOK.toLowerCase() !== 'yes' ? p.wheelRequestDetails : '';
                });
                customerService.roadsideServiceRequest(vm.requestData).$promise.then(function (result) {
                    $window.scrollTo(0, 0);
                    $state.go('customers.roadside-service-request-complete');
                }).catch(function (err) {
                    $location.hash('submitBtn');
                    console.error(err);
                    vm.submitErr = true;
                });
            }
        }

        function selectTireSize(idx) {
            if (vm.requestData.problems[idx].tireSizeOption === 'other') {
                vm.requestData.problems[idx].tireSize = '';
            } else {
                vm.requestData.problems[idx].tireSize = vm.requestData.problems[idx].tireSizeOption;
            }
        }

        function selectTirePosition(idx) {
            if (vm.requestData.problems[idx].tirePositionOption === 'other') {
                vm.requestData.problems[idx].tirePosition = '';
            } else {
                vm.requestData.problems[idx].tirePosition = vm.requestData.problems[idx].tirePositionOption;
            }

            var problems = angular.copy(vm.requestData.problems);
            if (problems.length > 1) {
                problems.splice(idx, 1);
                var currentProblem = vm.requestData.problems[idx];
                var currentPosition = vm.truckTirePositions.find(function (position) {
                    return position.value === currentProblem.tirePosition;
                });
                problems.forEach(function (p) {
                    var position = vm.truckTirePositions.find(function (position) {
                        return position.value === p.tirePosition;
                    });
                    if (currentProblem.problemArea === p.problemArea && position.type === currentPosition.type) {
                        currentProblem.tireSizeOption = p.tireSizeOption;
                        currentProblem.tireSize = p.tireSize;
                        currentProblem.tirePreference = p.tirePreference;
                    }
                });
            }
        }

        function selectProblemArea(idx) {
            var problems = angular.copy(vm.requestData.problems);
            if (problems.length > 1) {
                problems.splice(idx, 1);
                var currentProblem = vm.requestData.problems[idx];

                problems.forEach(function (p) {
                    if (currentProblem.problemArea === 'truck' && p.truckNumber) {
                        currentProblem.truckNumber = p.truckNumber;
                    }

                    if (currentProblem.problemArea === 'trailer' && p.trailerNumber) {
                        currentProblem.trailerNumber = p.trailerNumber;
                    }
                });
            }
        }
    }
})();