'use strict';

(function () {
    angular.module('organizations').config(routeConfig);

    routeConfig.$inject = ['$stateProvider'];

    function routeConfig($stateProvider) {
        $stateProvider.state('customers', {
            abstract: true,
            url: '/customers',
            template: '<ui-view/>',
            data: {
                roles: ['admin', 'developer', 'organizationHead']
            }
        }).state('customers.roadside-service-request', {
            url: '/roadside-service-request',
            resolve: {
                externalRedirect: ['$window', function ($window) {
                    $window.location.href = 'http://connect.stmtires.com';
                }]
            },
            data: {
                requiresLogin: false
            }
        })
        // .state('customers.roadside-service-request', {
        //     url: '/roadside-service-request',
        //     templateUrl: 'modules/customers/features/customer-roadside-service-request/customer-roadside-service-request.client.view.html',
        //     controller: 'RoadsideServiceRequestController',
        //     controllerAs: 'vm',
        //     data: {
        //         pageTitle: 'Roadside Service Request',
        //         requiresLogin: false
        //     }
        // })
        .state('customers.roadside-service-request-complete', {
            url: '/roadside-service-request-complete',
            templateUrl: 'modules/customers/features/customer-roadside-service-request/customer-roadside-service-request-complete.client.view.html',
            data: {
                requiresLogin: false
            }
        });
    }
})();