'use strict';

// Use application configuration module to register a new module
ApplicationConfiguration.registerFeatureModule('stores', 'stores.store-details');

// This is the TextAngular plugin configuration
angular.module('stores.store-details').config(textAngularConfig);

textAngularConfig.$inject = ['$provide'];

function textAngularConfig($provide) {
    // provider-injector
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', function (taRegisterTool, taOptions) {
        // $delegate is the taOptions we are decorating
        // register the tool with textAngular
        var colors = ['Black', 'Red', 'Green', 'Blue'];
        colors.forEach(function (theColor) {
            taRegisterTool('colour' + theColor, {
                iconclass: 'fa fa-square ' + theColor.toLowerCase(),
                action: function action() {
                    this.$editor().wrapSelection('foreColor', theColor.toLowerCase());
                }
            });

            // add the button to the default toolbar definition
            taOptions.toolbar[1].push('colour' + theColor);
        });
        return taOptions;
    }]);
}