'use strict';

(function () {
    angular.module('users.settings').controller('UsersSettingsController', UsersSettingsController);

    //dependency injections for the controller go here
    UsersSettingsController.$inject = ['Users', 'Authentication'];

    function UsersSettingsController(Users, Authentication) {

        /*jshint validthis: true */
        var vm = this;

        //bindable members go here
        vm.user = Authentication.user;
        vm.changeUserPassword = changeUserPassword;
        vm.success = null;
        vm.error = null;
        vm.passwordDetails = null;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {}

        // Change user password
        function changeUserPassword() {
            vm.success = vm.error = null;

            Users.changePassword(vm.passwordDetails).$promise.then(function () {
                // If successful show success message and clear form
                vm.success = true;
                vm.passwordDetails = null;
            }, function (err) {
                vm.error = err.data.message;
            });
        }
    }
})();