'use strict';

/* globals _ google */
//Start by defining the main module and adding the module dependencies
angular.module(ApplicationConfiguration.applicationModuleName, ApplicationConfiguration.applicationModuleVendorDependencies);

// Setting HTML5 Location Mode
angular.module(ApplicationConfiguration.applicationModuleName).config(['$locationProvider', function ($locationProvider) {
    $locationProvider.hashPrefix('!');
}]);

//Set up ui router auth and navigation history
angular.module(ApplicationConfiguration.applicationModuleName).run(routeSetup);
routeSetup.$inject = ['$rootScope', '$state', 'Authentication', '$window', 'DetailsEditService'];
function routeSetup($rootScope, $state, Authentication, $window, DetailsEditService) {
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {

        //keep track of previous state
        $rootScope.previousState = fromState;
        $rootScope.previousStateParams = fromParams;
        // console.log('Upcoming state');
        // console.log(toState);
        // console.log('Upcoming params');
        // console.log(toParams);

        //handle auth
        var requiresLogin = true;
        var allowedRoles = ['*'];

        //set up defaults for requiresLogin and roles
        if (toState.data && toState.data.requiresLogin === false) {
            requiresLogin = toState.data.requiresLogin;
        }
        if (toState.data && toState.data.roles) {
            allowedRoles = toState.data.roles;
        }

        var _redirectToSignin = function _redirectToSignin() {
            $window.location.href = '/api/auth/openidconnect';
            event.preventDefault();
        };

        var _redirectToNotAuthorized = function _redirectToNotAuthorized() {
            $state.transitionTo('not-authorized');
            event.preventDefault();
        };

        // Prevent users that cannot edit from accessing editable pages
        var stateNameSplitArray = _.split(toState.name, '.');
        var stateHasEditOrCreateCapabilities = _.some(stateNameSplitArray, function (pieceOfStateName) {
            return pieceOfStateName === 'edit' || pieceOfStateName === 'create';
        });
        if (stateHasEditOrCreateCapabilities && !Authentication.user.canEdit) {
            _redirectToNotAuthorized();
        }

        if (toParams.inGlobalEdit) {
            event.preventDefault();
            DetailsEditService.toggleSaveChangesWarning();
        } else if (requiresLogin && !Authentication.user) {
            return _redirectToSignin();
        } else if (allowedRoles.indexOf('*') === -1 && Authentication.user) {
            if (Authentication.user.roles && Authentication.user.roles.length) {
                for (var i = 0; i < Authentication.user.roles.length; i++) {
                    if (allowedRoles.indexOf(Authentication.user.roles[i]) > -1) {
                        return;
                    }
                }

                return _redirectToNotAuthorized();
            }
        }

        // prevent users that can't edit from accessing editable pages
    });
}

//Then define the init function for starting up the application
angular.element(document).ready(function () {
    //Fixing facebook bug with redirect
    if (window.location.hash === '#_=_') window.location.hash = '#!';

    //Then init the app
    angular.bootstrap(document, [ApplicationConfiguration.applicationModuleName]);
});