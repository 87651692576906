'use strict';

(function () {
    // Patients demographics controller
    angular.module('admin.dashboard').controller('AdminDashboardController', AdminDashboardController);

    AdminDashboardController.$inject = [];

    function AdminDashboardController() {

        /*jshint validthis: true */
        var vm = this;

        //bindable members go here
        vm.error = null;
        vm.selectedTab = 'organizations';

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {}
    }
})();