'use strict';

(function () {
    /**
     * String.includes for IE and Opera
     */
    if (!String.prototype.includes) {
        String.prototype.includes = function (search, start) {
            'use strict';

            if (typeof start !== 'number') {
                start = 0;
            }

            if (start + search.length > this.length) {
                return false;
            } else {
                return this.indexOf(search, start) !== -1;
            }
        };
    }

    /**
     * String.startsWith for IE
     */
    if (!String.prototype.startsWith) {
        String.prototype.startsWith = function (searchString, position) {
            position = position || 0;
            return this.substr(position, searchString.length) === searchString;
        };
    }
})();