'use strict';

(function () {
    // Notes controller
    angular.module('stores.store-details').controller('NotesController', NotesController);

    NotesController.$inject = ['$mdDialog', 'newNote', 'note'];

    function NotesController($mdDialog, newNote, note) {
        var vm = this;

        vm.newNote = newNote;
        vm.note = note;

        vm.cancel = function () {
            $mdDialog.cancel();
        };

        vm.save = function (note) {
            $mdDialog.hide(note);
        };
    }
})();