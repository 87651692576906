'use strict';

/* globals _ */
(function () {
    // Store details controller
    angular.module('stores.store-details').controller('StoreDetailsDirectoryController', StoreDetailsDirectoryController);

    StoreDetailsDirectoryController.$inject = ['storeResolve', '$mdDialog', 'Authentication', 'Stores', '$scope', 'DetailsEditService', 'uiGridConstants'];

    function StoreDetailsDirectoryController(store, $mdDialog, Authentication, Stores, $scope, DetailsEditService, uiGridConstants) {
        var vm = this;

        var authentication = Authentication;
        var viewColumns = [{ name: 'Type', cellTemplate: '<div class="text-center directory-type-icon"><span ng-class="{\'glyphicons\':1, \'glyphicons-usd\':row.entity.type===\'sales\', \'glyphicons-tractor\':row.entity.type===\'otr\', \'glyphicons-user\':row.entity.type===\'manager\', \'glyphicons-wrench\':row.entity.type===\'tech\'}"></span></div>', width: 47 }, { name: 'Contact Name', field: 'name', width: 150, cellClass: '', headerCellClass: '', sort: { direction: 'asc', priority: 0 } }, { name: 'Phone Number', field: 'phone', width: 110, cellClass: '', headerCellClass: '', cellTemplate: '<a href="tel:{{grid.getCellValue(row,col)}}"> {{grid.getCellValue(row,col) | format : \'phone\'}}</a>' }, { name: 'Backup Number', field: 'backupPhone1', width: 110, cellClass: '', headerCellClass: '', cellTemplate: '<a href="tel:{{grid.getCellValue(row,col)}}"> {{grid.getCellValue(row,col) | format : \'phone\'}}</a>' }, { name: 'ID', field: 'maddenCoId', width: 60, cellClass: '', headerCellClass: '' }, { name: 'Open Store?', cellTemplate: '<div class="text-center"><span ng-class="{\'glyphicons glyphicons-keys\':row.entity.openStore}"></span></div>', width: 90 }, { name: 'Notes', field: 'notes', cellClass: '', headerCellClass: '' }];
        var editColumns = [{ name: 'Edit', cellTemplate: '<a><span class="glyphicons glyphicons-edit" ng-click="grid.appScope.editDirectoryRow($event, grid.rows.indexOf(row))"></span></a>', width: 38 }, { name: 'Type', cellTemplate: '<div class="text-center directory-type-icon"><span ng-class="{\'glyphicons\':1, \'glyphicons-usd\':row.entity.type===\'sales\', \'glyphicons-tractor\':row.entity.type===\'otr\', \'glyphicons-user\':row.entity.type===\'manager\', \'glyphicons-wrench\':row.entity.type===\'tech\'}"></span></div>', width: 45 }, { name: 'Contact Name', field: 'name', width: 150, cellClass: '', headerCellClass: '', sort: { direction: 'asc', priority: 0 } }, { name: 'Phone Number', field: 'phone', width: 110, cellClass: '', headerCellClass: '', cellTemplate: '<a href="tel:{{grid.getCellValue(row,col)}}"> {{grid.getCellValue(row,col) | format : \'phone\'}}</a>' }, { name: 'Backup Number', field: 'backupPhone1', width: 110, cellClass: '', headerCellClass: '', cellTemplate: '<a href="tel:{{grid.getCellValue(row,col)}}"> {{grid.getCellValue(row,col) | format : \'phone\'}}</a>' }, { name: 'ID', field: 'mId', width: 60, cellClass: '', headerCellClass: '' }, { name: 'Open Store?', cellTemplate: '<div class="text-center"><span ng-class="{\'glyphicons glyphicons-keys\':row.entity.openStore}"></span></div>', width: 90 }, { name: 'Notes', field: 'notes', cellClass: '', headerCellClass: '' }, { name: 'Del', cellTemplate: '<div><span class="glyphicons glyphicons-delete" style="color: #A00;" ng-click="grid.appScope.deleteDirectoryRow(row, grid.rows.indexOf(row))"></span></div>', width: 44 }];

        var gridOptions = {
            columnDefs: viewColumns,
            enableHorizontalScrollbar: 0,
            enableVerticalScrollbar: 2,
            enableSorting: false,
            enableColumnMenus: false,
            rowHeight: 25,
            onRegisterApi: function onRegisterApi(api) {
                vm.gridApi = api;
            },
            appScopeProvider: vm
        };

        // eslint-disable-next-line no-unused-vars
        var storeListener = $scope.$on('store-update', function (event, args) {
            if (vm.store._id === args.store._id) {
                vm.store = args.store;
            }
        });

        //exposed functions
        vm.showEditIcon = showEditIcon;
        vm.showCancelSave = showCancelSave;
        vm.startEdit = startEdit;
        vm.save = save;
        vm.cancelEdit = cancelEdit;
        vm.editDirectoryRow = editDirectoryRow;
        vm.deleteDirectoryRow = deleteDirectoryRow;
        vm.addDirectoryEntry = addDirectoryEntry;
        vm.canAddDirectoryEntries = canAddDirectoryEntries;

        vm.getGlobalEditMode = DetailsEditService.getGlobalEditMode;
        vm.setGlobalEditMode = DetailsEditService.setGlobalEditMode;

        //bindable members go here
        vm.store = store;
        vm.directory = store.directory;
        vm.edit = {};
        vm.error = null;
        vm.rightNow = moment();
        vm.day = vm.rightNow.day();
        vm.editing = false;
        vm.directoryGridOptions = gridOptions;
        vm.directory = store.directory;

        //initialize the controller
        activate();

        //function declarations go here
        function activate() {
            gridOptions.data = vm.directory;
            //remove the listener when the scope is destroyed
            $scope.$on('$destroy', storeListener);
        }

        function showEditIcon() {
            return !vm.editing && authentication.user.canEdit;
        }
        function showCancelSave() {
            return vm.editing || false;
        }

        function startEdit() {
            vm.setGlobalEditMode(true);
            vm.edit.directory = [];
            _.forEach(vm.directory, function (dir) {
                vm.edit.directory.push(angular.copy(dir));
            });
            vm.directoryGridOptions.columnDefs = editColumns;
            vm.directoryGridOptions.data = vm.edit.directory;
            vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            vm.editing = true;
        }
        function finishEdit() {
            vm.directoryGridOptions.columnDefs = viewColumns;
            delete vm.edit.directory;
            vm.directoryGridOptions.data = vm.directory;
            vm.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            vm.editing = false;
            vm.setGlobalEditMode(false);
        }
        function cancelEdit() {
            vm.setGlobalEditMode(false);
            finishEdit();
        }
        function save() {
            function saveSuccess(store) {
                vm.directory = store.directory;
                finishEdit();
            }
            function saveError(err) {
                console.log(err); //eslint-disable-line no-console
            }
            var updatedStore = vm.store.toJSON(),
                newInfo = vm.edit.directory;

            updatedStore.directory = newInfo;

            Stores.updateStore(vm.store._id.toString(), updatedStore, saveSuccess, saveError);
        }
        function editDirectoryRow(event, index) {
            if (!vm.editing || !authentication.user.canEdit) {
                return null;
            }

            var dirEntry = {},
                createNew = index == null;
            if (!createNew) {
                dirEntry = angular.copy(vm.edit.directory[index]);
            }

            var dialogOptions = {
                locals: { entry: dirEntry, create: createNew },
                templateUrl: 'modules/stores/features/store-details/directory/store-details.directory-modal.client.view.html',
                parent: angular.element(document.body),
                targetEvent: event,
                clickOutsideToClose: true,
                fullscreen: true,
                bindToController: true,
                controllerAs: 'directory',
                controller: function controller(mdDialog) {
                    var vm = this;
                    vm.uiMaskOptions = { addDefaultPlaceholder: false };
                    vm.cancel = function () {
                        mdDialog.cancel();
                    };
                    vm.save = function (d) {
                        mdDialog.hide(d);
                    };
                }
            };
            dialogOptions.controller.$inject = ['$mdDialog'];

            $mdDialog.show(dialogOptions).then(function (dir) {
                if (index !== undefined) {
                    vm.edit.directory[index] = dir;
                } else {
                    vm.edit.directory.push(dir);
                }
            });
        }
        function deleteDirectoryRow(row, index) {
            vm.edit.directory.splice(index, 1);
        }
        function addDirectoryEntry(event) {
            return editDirectoryRow(event);
        }
        function canAddDirectoryEntries() {
            return vm.editing && authentication.user.canEdit;
        }
    }
})();